<script setup>
  import { formatDate } from "../../composables/formatDate";
  import RBMImage from "./../../components/Utility/RBMImage.vue";

  const props = defineProps({
    order: Object
  });

  const formattedDate = formatDate(props.order.date);
</script>

<template>
  <article class="dashboard-order-item">
    <div class="dashboard-order-item__content">
      <div class="dashboard-order-item__header">
        <!-- TO DO HARDCODED -->
        <span class="dashboard-order-item__date">Besteld op: {{ formattedDate }}</span>
        <span class="dashboard-order-item__number">Ordernummer: {{ order.order_number }}</span>
      </div>
      <div class="dashboard-order-item__main">
        <div class="dashboard-order-item__slider slider slider--dashboard">
          <div class="slider__items">
            <div class="slider__swiper swiper swiper--buttons-sibling swiper--vue">
              <div class="slider__swiper-wrapper swiper-wrapper">
                <article class="dashboard-order-item__product swiper-slide" v-for="product in order.products" :key="product.id">
                  <div class="dashboard-order-item__product-content">
                    <div class="dashboard-order-item__product-image" v-if="product.image_object">
                      <figure class="asp-base">
                        <RBMImage :image="product.image_object"/>
                      </figure>
                    </div>
                    <div class="dashboard-order-item__product-content">
                      <span class="dashboard-order-item__product-text notranslate">
                        <strong>{{ product.quantity }}x</strong>{{ product.title }}
                      </span>
                    </div>
                  </div>

                </article>
              </div>
            </div>
            <div class="slider__buttons swiper__buttons">
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-order-item__footer">
        <router-link
          :to="{name: 'Bestelling', params: { id: order.id }}"
          class="btn-text--smedium--primary-600"
        >
          <!-- TO DO HARDCODED -->
          Bekijk deze bestelling
        </router-link>
      </div>
    </div>
  </article>
</template>
