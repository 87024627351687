
export function useCollapsible() {
    window.addEventListener('load', function () {
        collapsibleHandler();
    });

    window.addEventListener('resize', function () {
        collapsibleResizer();
    });

    window.addEventListener('orientationchange', function () {
        collapsibleResizer();
    });

    function collapsibleHandler() {
        let collapsibleItems = document.querySelectorAll('.collapsible__item');
        collapsibleItems.forEach((collapsibleItem) => {
            collapsibleItem.querySelector('button').addEventListener('click', function() {
                collapsibleClick(collapsibleItem, this);
            });
        });
    }

    function collapsibleClick(collapsibleItem, clickedElm) {
        let collapsibleContent = collapsibleItem.querySelector('.collapsible__item-content');
        let collapsibleText = collapsibleItem.querySelector('.collapsible__item-text');

        if (collapsibleItem.classList.contains('collapsible__item--active')) {
            collapsibleClose(collapsibleItem, clickedElm, collapsibleContent);
        } else {
            collapsibleOpen(collapsibleItem, clickedElm, collapsibleContent, collapsibleText);
        }
    }

    function collapsibleOpen(collapsibleItem, clickElm, collapsibleContent, collapsibleText) {
        clickElm.setAttribute('aria-expanded', 'true');
        collapsibleItem.classList.add('collapsible__item--active');

        collapsibleContent.style.maxHeight = `${collapsibleText.offsetHeight}px`;
    }

    function collapsibleClose(collapsibleItem, clickElm, collapsibleContent) {
        clickElm.setAttribute('aria-expanded', 'false');
        collapsibleItem.classList.remove('collapsible__item--active');

        collapsibleContent.style.maxHeight = "0px";
    }

    function collapsibleResizer() {
        let activecollapsibleElm = document.querySelectorAll('.collapsible__item--active');

        activecollapsibleElm.forEach((collapsibleItem) => {
            let collapsibleItemText = collapsibleItem.querySelector('.collapsible__item-text');
            collapsibleItem.querySelector('.collapsible__item-content').style.maxHeight =  `${collapsibleItemText.offsetHeight}px`;
        });
    }
}
