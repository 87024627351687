<script setup>
  import CheckoutAddress from "./Address/CheckoutAddress.vue";
  import CheckoutExistingAddress from "./Address/CheckoutExistingAddress.vue";
  import CheckoutSteps from "./CheckoutSteps.vue";
  import CheckoutCartAside from "./CheckoutCartAside.vue";
  import CheckoutOverview from "./Overview/CheckoutOverview.vue";
  import CheckoutLogin from "./Login/CheckoutLogin.vue";
  import { useShippingStore } from "../../stores/shipping";
  import { onMounted, ref, watch, onBeforeMount } from "vue";
  import { fetchLanguage } from "@/mixins/appendLanguageURL";

  const props = defineProps({
    currentStepUrl: String,
    privacyUrl: String,
    tocUrl: String
  })

  const shippingStore = useShippingStore();

  const steps = ref([])
  const stepData = ref([]);

  const loggedIn = ref(false);

  const currentStep = ref({});
  const url = ref(null);

  let loading = ref(true);

  watch(() => steps.value, () => {
      currentStep.value = steps.value.find((step) => {
        return step.url.includes(url.value.current);
    });
  });

  onBeforeMount(() => {
    shippingStore.fillShippingStore();
  });

  const setStepData = (data) => {
    steps.value = data.steps;
    url.value = data.urls;
    stepData.value = data;
    
    if (data?.isLoggedIn) {
      loggedIn.value = data?.isLoggedIn;
    }
  }

  onMounted(async () => {
    await fetchStep(props.currentStepUrl);

    if (stepData.value.cart.shipping_method_id === null) {
      await shippingStore.updateShippingMethod();
    }
  });

  const fetchStep = async (stepUrl) => {
    await fetch(stepUrl)
        .then(response => response.json())
        .then((data) => {
          setStepData(data);
          window.scrollTo(0,0);
          loading = false;
        });
  }
</script>

<template>
  <!-- TODO: HARDCODED -->
  <section class="checkout" aria-label="Afrekenen">
    <div class="checkout__container">      
      <CheckoutSteps  @go="fetchStep" :step="currentStep.order" />
      <template v-if="!loading">
        <CheckoutLogin @success="fetchStep(url.next)" v-if="currentStep.order === 0"/>
        <CheckoutAddress  @go="fetchStep" @success="fetchStep(url.next)" v-else-if="currentStep.order === 1 && !loggedIn" :data="stepData" :privacy-url="props.privacyUrl" :toc-url="props.tocUrl" />
        <CheckoutExistingAddress  @go="fetchStep" @success="fetchStep(url.next)" v-else-if="currentStep.order === 1 && loggedIn" :data="stepData"/>
        <CheckoutPayment @back="fetchStep(url.previous)" @success="fetchStep(url.next)"  v-else-if="currentStep.order === 2" :data="stepData"/>
        <CheckoutOverview @go="fetchStep" @back="fetchStep(url.previous)" v-else-if="currentStep.order === 3" :data="stepData"/>
      </template>
      <template v-else>
        <div class="skeleton__left">
          <span class="skeleton__title"></span>
          <span class="skeleton__block"></span>
        </div>
      </template>
      <CheckoutCartAside v-if="currentStep.order !== 0"/>
    </div>
  </section>
</template>

<style lang="scss" scoped>
	@import '../../../sass/elements/skeletons.scss';

  .skeleton__left {
    @apply col-span-8;
    @apply md:col-span-12;
  }

  .skeleton__title {
    @extend .skeleton;
    @apply h-10 w-1/2 mb-8;
  }

  .skeleton__block {
    @extend .skeleton;
    @apply w-full h-[436px];
  }
</style>
