import { defineStore } from 'pinia';
import { useCartService } from '../services/cartService';
import { useShippingStore } from "./shipping";

const cartService = useCartService();

export const useCartStore = defineStore('cart', {
    state: () => {
        return {
            cartItems: [],
            quantitySelectorValue: 0,
            // cartSkeleton: true,
            cartLoaded: false,
            cartIsLoading: false,
            cartIsReloading: false,
            cartLogos: [],
            response: null,
            addresses: {
                shipping: null,
                billing: null,
            }
        };
    },
    actions: {
        checkForExistingItem(id) {
            return this.cartItems.some((i) => i.product_id === id);
        },
        async fetchCart(withInfo = false) {
            while (this.cartIsLoading) {
                await new Promise(resolve => setTimeout(resolve, 500)); // Wait while loading
            }

            if (this.cartLoaded) return;

            this.cartIsLoading = true;

            try {
                const response = await fetch('/api/cart/get');

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();

                this.cartItems = Object.values(result.data.products);
                this.response = result;

                if (withInfo) {
                    await this.fetchCartInfo(withInfo);
                }

                if (result.data.shipping_method_id === null) {
                    await useShippingStore().updateShippingMethod();
                }

                this.cartLoaded = true;
            } catch (error) {
                console.error('Failed to fetch cart data:', error);
            } finally {
                this.cartIsLoading = false;
            }
        },
        async updateCart() {
          while (this.cartIsReloading) {
            await new Promise(resolve => setTimeout(resolve, 500)); // Wait while loading
          }

          this.cartIsReloading = true;

          try {
            const response = await fetch('/api/cart/get');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            this.cartItems = Object.values(result.data.products);
            this.response = result;
          } catch (error) {
              console.error('Failed to fetch cart data:', error);
          } finally {
              this.cartIsReloading = false;
          }
        },
        async fetchCartInfo()
        {
            try {
                const response = await fetch('/api/cart');

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();

                this.cartLogos = Object.values(result.payment_icons);
                useShippingStore().setShippingItems(result.available_shipping_methods);
            } catch (error) {
                console.error('Failed to fetch cart info data:', error);
            } finally {
                this.cartIsLoading = false;
            }
        },
        async editItemInCart(item, newQuantity) {
            if (this.checkForExistingItem(item.product_id)) {
                const existingItem = this.cartItems.find((i) => i.id === item.id);
                existingItem.amount = newQuantity;

                cartService.modifyItem(item.product_id, newQuantity);
            }
        },
        async deleteItemInCart(item) {
            cartService.modifyItem(item.product_id, 0);

            const index = this.cartItems.findIndex(cartItem => cartItem.id === item.id);
            if (index !== -1) {
                this.cartItems.splice(index, 1);
            }
        },
        async addItemToCart(item, amount) {
            await cartService.addItem(item.id, amount || 1);

            if (this.checkForExistingItem(item.id)) {
                const existingItem = this.cartItems.find((i) => i.product_id === item.id);
                existingItem.amount += amount;
            } else {
                this.cartLoaded = false;
                await this.fetchCart();
            }
        },

        async getRelatedProductsFromItem(item) {
            cartService.getRelatedProducts(item.id);
        }
    },
    getters: {
        totalQuantity: (state) => {
            return state.cartItems.reduce((acc, item) => acc + item.amount, 0);
        },
        getTotalCartPrice : (state) => {
            return state.cartItems.reduce((acc, item) => acc + (item.amount * item.price), 0);
        },
    }
});
