<script setup>
  import { ref, watch } from 'vue';

  import CartItem from "./CartItem.vue";
  import CartShippingMethod from "./CartShippingMethod.vue";
  import CartAsidePricing from "../Cart/CartAsidePricing.vue";
  import CartAsideFooter from '../Cart/CartAsideFooter.vue';

  import { useCartStore } from "../../stores/cart";
  const cartStore = useCartStore();

  import { useShippingStore } from "../../stores/shipping";
  const shippingStore = useShippingStore();

  let amountOfItemMutations = ref(0);
  let amountOfShippingMutations = ref(0);

  const props = defineProps({
    infobox: Object,
    office: Object,
  });

  const updateShippingMethod = (shippingId) => {
    shippingStore.updateShippingMethod(shippingId);
  };

  watch(
    () => cartStore.getTotalCartPrice,
    (newVal) => {
      if (newVal) {
        amountOfItemMutations.value++;

        if (amountOfItemMutations.value > 1) {
          cartStore.updateCart();
        }
      }
    }
  );

  watch(
    () => shippingStore.getSelectedShippingMethodPrice,
    (newVal) => {
      if (newVal) {
        amountOfShippingMutations.value++;

        if (amountOfShippingMutations.value > 1) {
          cartStore.updateCart();
        }
      }
    }
  );

  const cartAsideShippingTitleText = ref(cartAsideShippingTitleTextCode);
</script>

<template>
  <div class="cart__overview">
    <div class="cart__shipping--responsive" v-if="!cartStore.cartIsLoading">
      <form>
        <CartShippingMethod
            v-for="shipping in shippingStore.items"
            :key="shipping.id"
            :shipping="shipping"
            :infobox="infobox"
            :office="office"
            @updateShippingMethod="updateShippingMethod"
        />
      </form>
    </div>
    <div class="cart__shipping--responsive" v-else>
      <span class="cart__aside-shipping-responsive--skeleton"><!-- --></span>
      <span class="cart__aside-shipping-responsive--skeleton"><!-- --></span>
    </div>
    <div class="cart__items">
      <div class="cart__items-inner" v-if="cartStore.cartIsLoading">
        <cart-item v-for="index in 2" :key="index" :loading=true />
      </div>
      <div class="cart__items-inner cart__items-inner--empty" v-else-if="cartStore.cartItems.length == 0">
        <p>Je winkelwagentje is leeg</p>
      </div>
      <div class="cart__items-inner" v-else>
        <cart-item v-for="item in cartStore.cartItems" :key="item.id" :item="item" :loading=false />
      </div>
    </div>
    <div class="cart__price--responsive">
      <CartAsidePricing />
    </div>

    <aside class="cart__aside">
      <div class="cart__aside-content">
        <!-- Make component -->
        <div class="cart__aside-shipping" v-if="shippingStore.items.length > 0">
          <span class="cart__aside-shipping-title">{{ cartAsideShippingTitleText }}</span> <!-- VUE_CART_ASIDE_SHIPPING_TITLE -->
          <form>
            <CartShippingMethod
                v-for="shipping in shippingStore.items"
                :key="shipping.id"
                :shipping="shipping"
                :infobox="infobox"
                :office="office"
                @updateShippingMethod="updateShippingMethod"
            />
          </form>
        </div>
        <div class="cart__aside-shipping" v-else>
          <span class="cart__aside-shipping-title--skeleton"><!-- --></span>
          <span class="cart__aside-shipping--skeleton"><!-- --></span>
          <span class="cart__aside-shipping--skeleton"><!-- --></span>
        </div>
        <!-- End of make component -->

        <CartAsidePricing />

        <CartAsideFooter />
      </div>
    </aside>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../sass/elements/skeletons.scss';

.cart__overview {
  @apply grid grid-cols-12 gap-3 mb-[60px] relative;
  @apply md:mb-9;
}

.cart__items {
  @apply col-span-8 pr-9;
  @apply md:col-span-12 md:pr-0;
}

.cart__items-inner {
    &:not(.cart__items-inner--empty) {
        @apply border-t border-solid border-border;
    }
}

.cart__aside {
  @apply col-span-4 col-start-9;
  @apply md:col-span-12 md:sticky md:bottom-0 md:inset-x-0 md:col-start-1;
}

.cart__aside-shipping {
  @apply px-9 py-6;
  @apply md:px-0 md:hidden;
}

.cart__shipping--responsive {
  @apply hidden;
  @apply md:block md:mb-1.5 md:col-span-12;
}

.cart__price--responsive {
  @apply hidden;
  @apply md:block md:col-span-12;

  .cart-aside__pricing{
    @apply md:block md:border-t-0 md:pt-2;
  }
}

.cart-aside__pricing {
    @apply md:hidden;
}

.cart__aside-shipping-title {
  @apply text-20_28_15_21 font-bold text-text block pb-3;
}

.cart__aside-shipping-title--skeleton {
  @apply w-[93px] h-[36px] inline-flex mb-1.5;
  @extend .skeleton;
}

.cart-aside__pricing--skeleton {
  @apply px-9 pt-6 pb-5 border-t border-solid border-border;
  @apply md:px-0 md:pt-4 md:pb-0 md:hidden;
}

.cart__aside-shipping--skeleton,
.cart__aside-pricing--skeleton {
  @apply w-full h-[27px] inline-flex;
  @apply md:hidden;
  @extend .skeleton;
}

.cart__aside-shipping-responsive--skeleton {
  @apply w-[221px] h-[27px] flex;
  @extend .skeleton;

  + .cart__aside-shipping-responsive--skeleton {
    @apply mob:mt-2.5;
  }
}

.cart__aside-vat--skeleton {
  @apply w-[87px] h-[20px] flex ml-auto;
  @apply md:hidden;
  @extend .skeleton;
}
</style>
