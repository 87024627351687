<script setup>
  import { ref, onMounted, onUnmounted, watch } from 'vue';
  import { useProfileStore } from '../stores/mainStore';
  import { useProfileWatcher } from "../../composables/useProfileWatcher";
  import { useSetNewSwipers } from "../../composables/useSetNewSwipers";
  import OrderRow from '../components/OrderRow.vue';
  import DataView from "primevue/dataview";

  const setNewSwipers = useSetNewSwipers();

  const profileStore = useProfileStore();
  const isLoadingRemainingOrders = ref(false);

  const initializeOrders = async () => {
    isLoadingRemainingOrders.value = true;
    await profileStore.fillOrders();
    isLoadingRemainingOrders.value = false;
  };

  const scrollToProducts = () => {
    const productsItems = document.getElementById('dashboard-orders__items');

    if (productsItems) {
      const topPosition = productsItems.getBoundingClientRect().top + window.scrollY - 200;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  };

  const paginationBehaviour = () => {
    scrollToProducts();
    setNewSwipers();
  }

  watch(
    () => profileStore.isPrimaryStoreFilled,
    (newVal) => {
      if (newVal) {
        initializeOrders();
      }
    }
  );

  const handleResize = () => {
    setNewSwipers();
  };

  onMounted(() => {
    if (profileStore.isPrimaryStoreFilled) {
      initializeOrders();
    }
    window.addEventListener('resize', handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  useProfileWatcher();
</script>

<template>
  <section class="dashboard-header dashboard-header--orders" aria-labelledby="dashboard__title">
    <div class="dashboard-header__content">
      <!-- TO DO HARDCODED -->
      <h1 id="dashboard__title">Bestellingen</h1>
    </div>
  </section>

  <section class="dashboard-orders" aria-label="Bestelling overzicht" v-if="profileStore.orders?.length > 0">
    <div class="dashboard-orders__items" id="dashboard-orders__items">
      <DataView
        :value="profileStore.orders"
        paginator
        :paginatorTemplate="'PrevPageLink PageLinks NextPageLink'"
        :rows="20"
        :alwaysShowPaginator="false"
        unstyled
        @page="paginationBehaviour"
      >
        <template #list="slotProps">
          <OrderRow
            v-for="order in slotProps.items"
            :order="order"
          />
        </template>
      </DataView>
    </div>
    <div
      class="dashboard-orders__items--loading"
      v-if="isLoadingRemainingOrders"
    ><i class="fa-regular fa-spinner"></i></div>
  </section>
  <div v-else-if="!profileStore.isPrimaryStoreFilled" class="dashboard-orders">
    <div class="dashboard-skeleton--block"><!-- --></div>
    <div class="dashboard-skeleton--block"><!-- --></div>
    <div class="dashboard-skeleton--block"><!-- --></div>
  </div> 
  <div v-else>
    <span class="">Geen bestellingen gevonden</span>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../sass/elements/skeletons.scss';

.dashboard-orders__items--loading {
  @apply text-center mt-12;
  @apply md:mt-9;
  @apply sm:mt-6;

  i {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dashboard-orders__items {
  @apply relative;
}

.dashboard-order:last-of-type {
  @apply mb-12;
  @apply sm:mb-3;
}

.dashboard-skeleton--block {
  @extend .skeleton;
  @apply h-[217px] w-full rounded;
  @apply xs:h-[169px];

  + .dashboard-skeleton--block {
    @apply mt-6;
    @apply xs:mt-3;
  }
}
</style>
