import DashboardOverview from "../../../../profile/layouts/DashboardOverview.vue";
import DashboardOrders from "../../../../profile/layouts/DashboardOrders.vue";
import DashboardOrder from "../../../../profile/layouts/DashboardOrder.vue";
import DashboardAppointments from "../../../../profile/layouts/DashboardAppointments.vue";
import DashboardDevices from "../../../../profile/layouts/DashboardDevices.vue";
import DashboardInformation from "../../../../profile/layouts/DashboardInformation.vue";
import DashboardAdresses from "../../../../profile/layouts/DashboardAdresses.vue";

export default [
  { path: '/dashboard', redirect: '/dashboard/overzicht' },
  { path: '/dashboard/overzicht', name: 'Overzicht', component: DashboardOverview },
  { path: '/dashboard/bestellingen', name: 'Bestellingen', component: DashboardOrders },
  { path: '/dashboard/bestelling/:id', name: 'Bestelling', component: DashboardOrder },
  { path: '/dashboard/service_afspraken', name: 'Service Afspraken', component: DashboardAppointments },
  { path: '/dashboard/mijn_apparaten', name: 'Mijn Apparaten', component: DashboardDevices },
  { path: '/dashboard/gegevens', name: 'Gegevens', component: DashboardInformation },
  { path: '/dashboard/adressen', name: 'Adressen', component: DashboardAdresses },
]

