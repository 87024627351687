<script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import DataView from "primevue/dataview";
import Select from "primevue/select";
import { useFiltersStore } from "../../stores/filters";
import AddToCartDialog from "../Utility/AddToCartDialog.vue";

const router = useRouter();
const route = useRoute();

const rows = ref(12);
const offset = ref(0);

// TO DO HARDCODED TEXTS (Populariteit, Price High to Low, Price Low to High)
const sortOptions = ref([
  { label: "Populariteit", value: "is_popular", order: -1 },
  { label: "Prijs hoog - laag", value: "actual_price", order: -1  },
  { label: "Prijs laag - hoog", value: "actual_price", order: 1  },
]);

const sortKey = ref(sortOptions.value[0]);
const sortOrder = ref(sortOptions.value[0].order);
const sortField = ref(sortOptions.value[0].value);

const onSortChange = (event) => {
  const value = event.value;

  sortKey.value = value;
  sortField.value = value.value;
  sortOrder.value = value.order;
};

const scrollToProducts = (e) => {
  onPaginate(e);
  const productsItems = document.getElementById('products__items');

  if (productsItems) {
    const topPosition = productsItems.getBoundingClientRect().top + window.scrollY - 200;
    window.scrollTo({ top: topPosition, behavior: 'smooth' });
  }
};

const onPaginate = (e) => {
  offset.value = e.rows * e.page;
  const query = { ...route.query }; // Need to use splat operator because vue router doesn't detect change if I just append the query
  query.page = e.page +1; // +1 because page in primevue starts at 0;

  router.push({path: '/' + router.currentRoute.value.params.slug, query: query });
};

const calculateOffset = (page) => {
  offset.value = rows.value * (page - 1); // -1 because page in primevue starts at 0;
};

onBeforeMount(() => {
  calculateOffset(useRoute().query.page ?? 1);
});

onBeforeRouteUpdate((to, from, next) => {
  calculateOffset(to.query.page ?? 1);
  next();
});
</script>

<template>
  <section class="products__items" id="products__items" aria-label="Producten overzicht">
    <DataView
      :value="useFiltersStore().items"
      paginator
      :paginatorTemplate="'PrevPageLink PageLinks NextPageLink'"
      :rows="rows"
      :sortOrder="sortOrder"
      :sortField="sortField"
      :alwaysShowPaginator="false"
      unstyled
      @page="scrollToProducts"
      :first="offset"
    >
      <template #header>
        <div class="products__items-header">
          <!-- TO DO HARDCODED TEXT -->
          <div class="products__items-header__text">
              <span class="products__items-header__count" v-if="useFiltersStore().totalItems && !useFiltersStore().filtersIsLoading">{{ useFiltersStore().totalItems }} resultaten</span>
              <span class="products__items-header__count products__items-header__count--skeleton" v-else-if="useFiltersStore().filtersIsLoading"><!-- --></span>
          </div>
          <div class="products__items-header__sort">
              <!-- TO DO HARDCODED TEXT -->
              <span class="products__items-header__label">Sorteren op:</span>

              <Select
                  v-model="sortKey"
                  :options="sortOptions"
                  optionLabel="label"
                  placeholder="Kies een optie"
                  @change="onSortChange($event)"
                  :value="sortKey"
                  :pt="{
                      root: { class: 'select__main' },
                      overlay: { class: 'select__overlay' },
                      label: { class: 'select__label' },
                      dropdown: { class: 'select__dropdown' }
                  }"
              />
          </div>
        </div>
      </template>

      <template #list="slotProps">
        <div class="products__items-inner">
          <ProductCard
            @openDialog="openDialog()"
            v-for="(product, index) in slotProps.items"
            :product="product"
            :key="index"
            :show-skeleton=useFiltersStore().filtersIsLoading
          />
          <AddToCartDialog />
        </div>
      </template>
      <template #empty>
        <div class="products__empty">Geen resultaten gevonden.</div>
      </template>
    </DataView>
  </section>
</template>

<style lang="scss" scoped>
@import '../../../sass/elements/skeletons.scss';

.products__items-header{
    @apply flex items-center justify-between flex-wrap border-b border-solid border-[#EBE9E6] pb-3;
}

.products__items-header__sort{
  @apply flex items-center gap-x-4;
  @apply sm:gap-x-2;
}

.products__items-header__label,
.products__items-header__count {
  @apply text-14_21_13_21 text-black;

  &--skeleton {
    @extend .skeleton;
    @apply h-[18px] w-[80px];
  }
}

.products__items {
  @apply col-span-9 row-start-2 row-end-3 col-start-4 relative;
  @apply md:col-span-12 md:row-start-auto md:row-end-auto md:col-start-auto;
}

.products__items-inner {
  @apply grid grid-cols-12 gap-6 gap-x-9 pt-3 mb-12;
  @apply md:gap-x-6;
  @apply sm:mb-3;
  @apply mob:gap-y-0 mob:pt-0;
}

.products__empty {
  @apply pt-3;
}

.product__item {
  @apply col-span-4;
  @apply sm:col-span-6;
  @apply mob:col-span-12;
}
</style>
