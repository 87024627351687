<script setup>
  import { ref, onMounted, onUnmounted} from 'vue';
  import Select from 'primevue/select';

  const props = defineProps({
    data: Object
  });

  const emit = defineEmits(['success', 'back']);

  const paymentMethods = props.data?.available_payment_methods;

  const activePaymentMethod = ref({});
  activePaymentMethod.value = props.data?.chosen_payment_method

  const activeIssuer = ref(activePaymentMethod?.value?.meta?.issuer ?? null);
  const buttonLoading = ref(false);

  const setActiveMethod = (method) => {
    activePaymentMethod.value = method;
  };

  const handleFormSubmit = () => {
    // todo check if active method not is null
    const data = {
      payment_method: activePaymentMethod.value.id
    }

    if (activePaymentMethod.value.meta.issuers && activeIssuer.value !== null) {
      data.payment_method_meta = {
        issuer: activeIssuer.value
      };
    } else if (activePaymentMethod.value.meta.issuers) {
    //   TODO: alert that issuer has to be set
    }

    buttonLoading.value = true;
    fetch('/api/checkout/choose-payment', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          sendDataLayers('payment info', {cartId: props.data.cart.id});
          emit('success',true);
          // We don't have to stop showing the loading state, because we will go to the next page anyway
          // buttonLoading.value = false;
        } else {
          // todo Either show an error and stop the loader, or show no error and never stop the loader
          // buttonLoading.value = false;
          // Handle error
        }
      })
      .catch(error => {
        // todo Handle error
      });
  }

  const isSmaller = ref(false);

  const handleIsSmaller = () => {
    isSmaller.value = window.innerWidth < 1224;

    window.addEventListener('resize', () => {
      isSmaller.value = window.innerWidth < 1224;
    });
  };

  onMounted(() => {
    handleIsSmaller();
    window.addEventListener('resize', handleIsSmaller);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleIsSmaller);
  });

  const paymentTitleText = ref(paymentTitleTextCode);
  const paymentOverviewButtonText = ref(paymentOverviewButtonTextCode);
  const backButtonText = ref(backButtonTextCode);

  const paymentPageForwardButtonText = ref(paymentPageForwardButtonTextCode);
</script>

<template>
    <section class="payment">
        <div class="payment-title">
            {{ paymentTitleText }} <!-- VUE_CO_PAY_TITLE -->
        </div>

        <div class="payment-methods" v-if="paymentMethods.length > 0">
            <div
                class="payment-method"
                v-for="(method, index) in paymentMethods"
                :key="index"
                :class="{ active: activePaymentMethod?.id == method.id }"
                @click="setActiveMethod(method)"
            >
                <div class="payment-method__image">
                    <img :src="method.icon" alt="payment method" v-if="method.icon">
                </div>
                <div class="payment-method__title">
                    {{ method.title }}
                </div>
                <template v-if="method.meta.issuers">
                    <Select v-model="activeIssuer" :options="method.meta.issuers" optionLabel="title" placeholder="Kies je bank" unstyled
                        :pt="{
                          root: { class: 'dropdown-primevue' },
                          listContainer: { class: 'dropdown-container-primevue' },
                        }"
                    />
                </template>
                <template v-if="!isSmaller">
                  <div class="payment-method-continue">
                    <a @click.prevent="handleFormSubmit" class="btn-solid--primary-600" :class="{disabled : !activeIssuer && activePaymentMethod.title == 'iDeal' || buttonLoading}" v-if="method.meta.issuers && activePaymentMethod.title == 'iDeal'">{{ paymentPageForwardButtonText }}</a>
                    <div class="form__submit-loading" v-if="buttonLoading">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </div>
                </template>
            </div>
        </div>
        <div class="payment-methods" v-else>
            <span
                class="payment-method--skeleton"
                v-for="(method, index) in paymentMethods"
                :key="index"
            >
            </span>
        </div>

        <div class="payment-navigation" v-if="paymentMethods.length > 0">
            <div>
              <a @click.prevent="emit('back')" class="btn-text-primary-600 payment-navigation--back">{{ backButtonText }}</a>
            </div>
            <div class="form__submit-button">
              <button type="button" class="btn-solid--primary-600" @click="handleFormSubmit" :disabled="!activeIssuer && activePaymentMethod.title == 'iDeal' || buttonLoading">
                {{ paymentOverviewButtonText }}
              </button>
              <div class="form__submit-loading" v-if="buttonLoading">
                <i class="fa-solid fa-spinner"></i>
              </div>
              <!-- <a @click.prevent="handleFormSubmit" class="btn-solid--primary-600" :class="{disabled : !activeIssuer && activePaymentMethod.title == 'iDeal' || buttonLoading}">Door naar overzicht</a> -->
            </div>
        </div>
        <div class="payment-navigation" v-else>
            <span class="payment-navigiaton-back--skeleton"><!-- --></span>
            <span class="payment-navigiaton-forward--skeleton"><!-- --></span>
        </div>
    </section>
</template>

<style lang="scss">
  .dropdown-primevue {
    @apply h-[54px] border border-solid border-[#E0E0E0] rounded-none flex justify-between items-center px-5 text-17_30_14_22 bg-white w-[297px] max-w-full;
    @apply mob:h-11 mob:px-3 mob:w-full;

    &:has(> span[aria-expanded="true"]) {
      @apply border-b-white;
    }

    span {
      @apply font-medium text-17_32_14_20 outline-none;
    }
  }

  .dropdown-container-primevue {
    @apply  bg-white border-x border-b border-solid border-[#E0E0E0] pt-0 overflow-auto;

    ul {
      li {
        @apply h-10 transition-all duration-300 px-5 flex items-center;
        @apply hover:cursor-pointer hover:bg-primary-300;
      }
    }
  }
</style>
<style lang="scss" scoped>
	@import '../../../sass/elements/skeletons.scss';

  .payment {
      @apply col-span-8;
      @apply md:col-span-12;
  }

  .payment-method__image {
      @apply max-w-[52px] mr-3 inline-flex flex-[0_0_52px];
      @apply mob:max-w-[40px] mob:flex-[0_0_40px];
  }
  .payment-title {
      @apply font-bold font-fontText text-24_28_18_26 mb-8;
      @apply mob:mb-3;
  }

  .payment-methods {
      @apply mb-11;
      @apply mob:mb-[18px];
  }

  .payment-method {
      @apply border border-solid border-border rounded w-full flex h-[100px] items-center pl-[30px] pr-6 transition-all duration-300 font-bold font-fontText text-black tracking-[0.34px] text-17_30_14_22 col-span-8 relative;
      @apply hover:cursor-pointer;
      @apply mob:px-4 mob:h-[73px];

      &:not(:last-child) {
          @apply mb-3;
          @apply xs:mb-[4px];
      }

      &.active {
          @apply bg-[#FAF2E6] bg-blend-multiply border-primary-500;

          &:hover {
              @apply bg-[#FAF2E6]/70;
          }

          .form__select {
              @apply block;
          }
      }

      &:hover {
          @apply bg-[#fff9f0] bg-blend-multiply border-primary-200;
      }

      .form__select {
        @apply ml-20;

          .form__select-input {
              @apply h-[54px] w-[297px];
              @apply mob:w-full;

              &:focus {
                  @apply rounded-none;
              }
          }
      }
  }

  .payment-method__title {
    @apply flex-[0_0_118px];
    @apply mob:flex-none mob:mr-6;
  }

  .payment-navigation {
      @apply flex justify-between items-center gap-3;

      .btn-solid--primary-600 {
          @apply px-[90px];
          @apply xs:px-6;

          &:disabled {
            @apply opacity-50 pointer-events-none;
          }
      }

      .payment-navigation--back {
          @apply text-15_36_14_18 text-[#BF8A30] whitespace-nowrap;
          @apply hover:text-primary-800 hover:cursor-pointer;
      }
  }

  .payment-method--skeleton {
      @apply h-[100px] w-full rounded;
      @extend .skeleton;

      &:not(:last-child) {
          @apply mb-3;
      }
  }

  .payment-method-continue {
    @apply relative ml-auto;

    a {
      &.disabled {
        ~.form__submit-loading {
          @apply hover:cursor-not-allowed;
        }
      }
    }
  }

  .payment-navigiaton-back--skeleton {
    @apply h-[19px] w-[66px];
    @extend .skeleton;
  }

  .payment-navigiaton-forward--skeleton {
    @apply h-[54px] w-[330px] rounded;
    @extend .skeleton;
  }
</style>
