<script setup>
  import { formatDate } from "../../composables/formatDate";

  const props = defineProps({
    appointment: Object
  });

  const formattedDate = formatDate(props.appointment.date);
</script>

<template>

<article class="dashboard-appointment">
    <div class="dashboard-appointment__content">
      <div class="dashboard-appointment__header">
        <!-- TO DO HARDCODED -->
        <span class="dashboard-appointment__status">{{ appointment.status }}</span>
        <span class="dashboard-appointment__date">Aangemeld op: {{ formattedDate }}</span>
      </div>
      <div class="dashboard-appointment__main">
        <div class="dashboard-appointment__image">

        </div>
        <div class="dashboard-appointment__text">
          <span class="dashboard-appointment__title">{{ appointment.product.title }}</span>
          <span class="dashboard-appointment__serial">{{ appointment.product.product_number }}</span>
          <p>{{ appointment.product.description }}</p>
        </div>
      </div>
    </div>
  </article>
</template>
