const labels = [];

export function useLabel() {


    const get = (key) => {
        return labels[key];
    }

    const set = (key, value) => {
        labels[key] = value;
    }

    const setMultiple = (labels = []) => {
        labels.forEach((label, key) => {
            set(key, label);
        });
    }

    return {
        get,
        set,
        setMultiple
    }
}