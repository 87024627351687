<script setup>
import { useFiltersStore } from "../../stores/filters";
import { useRoute, useRouter } from "vue-router";
import { useLabel } from "../../composables/useLabel";

const props = defineProps({
  products: Object,
  category: Object,
  filters: Object,
  categoryLabel: String
});

useLabel().set('PRODUCT_CATEGORY', props.categoryLabel);

useFiltersStore().setFilters({
  items: props.products,
  filters: props.filters,
  item: props.category
});

useRouter().beforeResolve((to, from) => {
  if (from.path !== "/") {
    useFiltersStore().updateFiltersByUrl(to.fullPath);
  }
});
</script>

<template>
  <bread-crumb></bread-crumb>
  <router-view />
</template>