<script setup>
import { ref } from "vue";
import Category from "./Category.vue";

const props = defineProps({
  data: Array,
});

const ourAssortmentText = ref(ourAssortmentTextCode);
</script>

<template>
  <bread-crumb :crumb="[]"></bread-crumb>
  <section class="categories-main" aria-labelledby="categories-main__title">
    <div class="categories-main__header">
      <h1 id="categories-main__title">{{ ourAssortmentText }}</h1>
    </div>

    <div class="categories-main__items">
      <Category v-for="category in data" :category />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.categories-main {
  @apply container;
  @apply lg:px-6;
  @apply sm:px-4;
}

h1 {
  @apply font-fontText font-bold text-32_32_20_20 text-dark mb-7;
}

.categories-main__items {
  @apply grid grid-cols-10 gap-x-3 gap-y-6 mb-16;
  @apply lg:grid-cols-12;
  @apply md:mb-9;
  @apply sm:gap-1;
}
</style>
