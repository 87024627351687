<script setup>
  import { useProfileStore } from "../stores/mainStore";
  import OrderRow from '../components/OrderRow.vue';
  import CartRow from '../components/CartRow.vue';
  import { useProfileWatcher } from "../../composables/useProfileWatcher";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const profileStore = useProfileStore();

  useProfileWatcher();
</script>

<template>
  <section class="dashboard-header dashboard-header--overview" aria-labelledby="dashboard__title" v-if="profileStore.isPrimaryStoreFilled">
    <div class="dashboard-header__content">
      <!-- TO DO HARDCODED -->
      <h1 id="dashboard__title">Overzicht{{profileStore.accountData?.first_name ? ' van ' + profileStore.accountData.first_name : '' }}</h1>
      <p>Welkom terug{{ profileStore.accountData?.first_name ? ' ' + profileStore.accountData.first_name : '' }}. In het accountoverzicht van Maunt kun je direct bij de laatste bestellingen die je gedaan hebt. Daarnaast is het mogelijk om jouw gegevens en voorkeuren aan te passen. Mocht je ergens niet helemaal uitkomen, mail dan jouw vraag naar <a href="mailto:sales@maunt.com">sales@maunt.com</a> en we helpen je binnen 24 uur weer op weg!</p>
    </div>
  </section>
  <div v-else class="dashboard-header--overview">
    <div class="dashboard-skeleton--title"><!-- --></div>
    <div class="dashboard-skeleton--text"><!-- --></div>
  </div>

  <section class="dashboard-overview dashboard-overview--order" aria-labelledby="dashboard-overview__title--order" v-if="profileStore.orders?.length > 0">
    <div class="dashboard-overview__header">
      <!-- TO DO HARDCODED TEXT -->
      <h2 id="dashboard-overview__title--order">Je laatste bestellingen</h2>
    </div>
    <div class="dashboard-overview__items">
      <OrderRow :order="profileStore.orders[0]" />
    </div>
    <div class="dashboard-overview__footer">
      <!-- TO DO HARDCODED TEXT -->
      <router-link class="btn-text--medium--primary-600" :to="{ name: 'Bestellingen' }">Bekijk alle bestellingen</router-link>
    </div>
  </section>
  <div v-else-if="!profileStore.isPrimaryStoreFilled" class="dashboard-header--overview">
    <div class="dashboard-skeleton--title-small"><!-- --></div>
    <div class="dashboard-skeleton--block"><!-- --></div>
  </div>

  <section class="dashboard-overview dashboard-overview--cart" aria-labelledby="dashboard-overview__title--cart" v-if="profileStore.cart.products?.length > 0">
    <div class="dashboard-overview__header">
      <!-- TO DO HARDCODED TEXT -->
      <h2 id="dashboard-overview__title--cart">Dit zit nog in je winkelwagen</h2>
    </div>
    <div class="dashboard-overview__items">
      <CartRow :products="profileStore.cart.products" />
    </div>
    <div class="dashboard-overview__footer">
      <!-- TO DO HARDCODED TEXT -->
      <a class="btn-text--medium--primary-600" :href="appendLanguagePrefixURL('/winkelwagen')">Bekijk winkelwagen</a>
    </div>
  </section>
  <div v-else-if="!profileStore.isPrimaryStoreFilled" class="dashboard-header--overview">
    <div class="dashboard-skeleton--title-small"><!-- --></div>
    <div class="dashboard-skeleton--block"><!-- --></div>
  </div>  

  <div class="dashboard-twocol">
    <section class="dashboard-information" aria-labelledby="dashboard-information__title" v-if="Object.keys(profileStore.accountData).length > 0">
      <div class="dashboard-information__heading">
          <!-- TO DO HARDCODED TEXT -->
        <h2 id="dashboard-information__title">Jouw gegevens</h2>
      </div>
      <div class="dashboard-information__content">
        <p>{{ profileStore.accountData.full_name }}</p>
        <p><i class="fa-solid fa-envelope"></i> {{ profileStore.accountData.email_address }}</p>
        <p><i class="fa-solid fa-phone"></i> {{ profileStore.accountData.phone_number ??  profileStore.accountData.mobile }}</p>
      </div>
      <div class="dashboard-information__button">
        <!-- TO DO HARDCODED TEXT -->
        <router-link class="btn-text--medium--primary-600" :to="{ name: 'Gegevens' }">Beheer je gegevens</router-link>
      </div>
    </section>
    <div v-else-if="!profileStore.isPrimaryStoreFilled" class="dashboard-information">
      <div class="dashboard-skeleton--title-full"><!-- --></div>
      <div class="dashboard-skeleton--block"><!-- --></div>
    </div>     

    <section class="dashboard-addresses" aria-labelledby="dashboard-addresses__title" v-if="profileStore.accountData?.default_addresses?.shipping && profileStore.accountData?.default_addresses?.billing">
      <div class="dashboard-addresses__heading">
          <!-- TO DO HARDCODED TEXT -->
        <h2 id="dashboard-addresses__title">Jouw bezorg- en factuuradres</h2>
      </div>
      <div class="dashboard-addresses__content">
        <div v-if="profileStore.accountData?.default_addresses.shipping">
          <p><strong>Bezorgadres</strong></p>
          <p class="notranslate">{{ profileStore.accountData?.default_addresses.shipping.address_line_one }}<br />
          {{ profileStore.accountData?.default_addresses.shipping.zip }} {{ profileStore.accountData?.default_addresses.shipping.city }}<br />
          {{ profileStore.accountData?.default_addresses.shipping.country }}</p>
        </div>
        <div v-if="profileStore.accountData?.default_addresses.billing">
          <p><strong>Factuuradres</strong></p>
          <p class="notranslate">{{ profileStore.accountData?.default_addresses.billing.address_line_one }}<br />
          {{ profileStore.accountData?.default_addresses.billing.zip }} {{ profileStore.accountData?.default_addresses.billing.city }}<br />
          {{ profileStore.accountData?.default_addresses.billing.country }}</p>
          </div>
      </div>
      <div class="dashboard-addresses__button">
        <!-- TO DO HARDCODED TEXT -->
        <router-link class="btn-text--medium--primary-600" :to="{ name: 'Adressen' }">Beheer je adressen</router-link>
      </div>
    </section>
    <div v-else-if="!profileStore.isPrimaryStoreFilled" class="dashboard-addresses">
      <div class="dashboard-skeleton--title-full"><!-- --></div>
      <div class="dashboard-skeleton--block"><!-- --></div>
    </div>         
  </div>
</template>

<style lang="scss" scoped>
  @import '../../../sass/elements/skeletons.scss';

  .dashboard-twocol {
    &:has(.dashboard-information + .dashboard-addresses) {
      @apply grid grid-cols-12 gap-3;

      .dashboard-information,
      .dashboard-addresses {
        @apply col-span-6 flex flex-col;
        @apply sm:col-span-12;
      }
    }
  }

  .dashboard-skeleton--title {
    @extend .skeleton;
    @apply h-[32px] w-[50%];
    @apply xs:w-full;

    &-small {
      @extend .skeleton;
      @apply h-[32px] w-[40%];
      @apply xs:w-full;
    }

    &-full {
      @extend .skeleton;
      @apply h-[32px] w-full;
      @apply xs:w-full;
    }
  }

  .dashboard-skeleton--text {
    @extend .skeleton;
    @apply h-[56px] w-full mt-3;
  }

  .dashboard-skeleton--block {
    @extend .skeleton;
    @apply h-[217px] w-full mt-3;
  }
</style>
