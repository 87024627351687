<script setup>
import { ref } from 'vue';
import { useCartStore } from "../../stores/cart.js"
import CartDialog from "./Dialogs/Cart.vue";
import { useDialog } from "../../composables/useDialog.js";

  const cartStore = useCartStore();

  const props = defineProps({
    product: Object,
    quantity: {
      type: Number,
      default: 1
    },
    smallButton: {
        type: Boolean,
        default: false
    }
  });

  const addItemToCart = async () => {
    const isMobile = ref(false);

    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }
    sendDataLayers('add to cart', {productId: props.product.id, amount: props.quantity});

    await cartStore.addItemToCart(props.product, props.quantity);
    useDialog().open(CartDialog, {'product': props.product});
  };
</script>

<template>
  <div class="product-cart__button" @click="addItemToCart">
    <button class="btn-solid--primary-600" :class="{ 'btn-solid--cart-small': smallButton }">
      <!-- TODO: HARDCODED TEXT-->
      <i class="fa-light fa-shopping-cart"></i> <span v-if="!smallButton">Plaats in winkelmandje</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.btn-solid--cart-small {
    @apply transition-colors duration-300 px-0 py-0 h-[54px] w-[74px] flex items-center justify-center text-[18px] z-10 relative;
}
</style>
