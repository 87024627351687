import { onMounted, watch } from "vue";
import { useSetNewSwipers } from './useSetNewSwipers';
import { useProfileStore } from "../profile/stores/mainStore";

export function useProfileWatcher() {
  const profileStore = useProfileStore();
  const setNewSwipers = useSetNewSwipers();

  onMounted(() => {
    setNewSwipers();
  });

  watch(
    () => profileStore,
    (newVal) => {
      if (newVal) {
        setNewSwipers();
      }
    },
    { deep: true }
  );
}
