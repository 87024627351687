<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    step: Number
  });

  const emit = defineEmits(['go']);

  const isPrevious = (number) => {
    return number < props.step;
  }

  const goTo = (url, stepNumber) => {
    if (isPrevious(stepNumber)) {
      emit('go', url);
    }
  }

  const checkoutStepsTitleText = ref(checkoutStepsTitleTextCode);
  const step1LabelText = ref(step1LabelTextCode);
  const step2LabelText = ref(step2LabelTextCode);
  const step3LabelText = ref(step3LabelTextCode);
  const step4LabelText = ref(step4LabelTextCode);
</script>

<template>
  <div class="checkout-steps__wrapper">
    <div class="checkout-steps__title">
        {{ checkoutStepsTitleText }} <!-- VUE_CO_STEPS_TITLE -->
    </div>
    <div class="checkout-steps">
      <div
          @click="goTo('/api/checkout/address', 1)"
          class="checkout-steps__step checkout-steps__step--first"
          :class="{ 'checkout-steps__step--active': props.step === 1, 'checkout-steps__step--previous': isPrevious(1) }"
      >
        <span>{{ step1LabelText }}</span> <!-- VUE_CO_STEP1_LABEL -->
      </div>
      <div
          @click="goTo('/api/checkout/choose-payment', 2)"
          class="checkout-steps__step"
          :class="{'checkout-steps__step--active': props.step === 2, 'checkout-steps__step--previous': isPrevious(2)}"
      >
        <span>
          {{ step2LabelText }} <!-- VUE_CO_STEP2_LABEL -->
        </span>
      </div>
      <div
          @click="goTo('/api/checkout/overview', 3)"
          class="checkout-steps__step"
          :class="{'checkout-steps__step--active': props.step === 3, 'checkout-steps__step--previous': isPrevious(3)}"
      >
        <span>{{ step3LabelText }}</span> <!-- VUE_CO_STEP3_LABEL -->
      </div>
      <div class="checkout-steps__step checkout-steps__step--last" :class="{ 'checkout-steps__step--active': props.step === 4 }">{{ step4LabelText }}
      </div> <!-- VUE_CO_STEP4_LABEL -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .checkout-steps__wrapper {
    @apply col-span-12 grid grid-cols-12 items-center mb-12;
    @apply md:mb-9;
    @apply sm:gap-y-3 sm:mb-0;
  }

  .checkout-steps__title {
    @apply text-32_32_20_20 font-bold col-span-2 font-fontText text-dark;
    @apply sm:col-span-12;
  }

  .checkout-steps {
    @apply col-span-10 flex;
    width: calc(100% + 30px);
    @apply lg:w-full;
    @apply sm:col-span-12;
    @apply mob:h-10;
  }

  .checkout-steps__step {
    @apply flex-grow bg-[#EBEBEB] py-2 px-7 font-fontText text-dark tracking-[0.3px] text-15_36_12_36 w-[calc(100%_+_5px)];
    @apply mob:tracking-[0.24px] mob:px-[9px] mob:flex mob:items-center mob:w-[calc(100%_+_10px)];
    clip-path: polygon(calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0% 100%, 15px 50%, 0% 0%);

    &:not(:first-child) {
      @apply mob:pl-4;
    }

    &.checkout-steps__step--previous {
      @apply cursor-pointer;
    }

    span {
      @apply relative z-10;
    }

    @media screen and (max-width: 600px) {
      clip-path: polygon(calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 10px 50%, 0% 0%);
    }

    &.checkout-steps__step--first {
      clip-path: polygon(calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0% 100%, 0% 0%);

      @media screen and (max-width: 600px) {
        clip-path: polygon(calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 0% 0%);
      }

      &.checkout-steps__step--active {
        @apply font-bold bg-[#CCA666] relative text-dark;

        &::after {
          @apply content-[""] bg-[#FAF2E6] absolute left-px top-px w-[calc(100%_-_2px)] h-[calc(100%_-_2px)];
          clip-path: polygon(calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0% 100%, 0% 50%, 0% 0%);

          @media screen and (max-width: 600px) {
            clip-path: polygon(calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 0% 50%, 0% 0%);
          }
        }
      }
    }

    &:nth-child(2) {
      transform: translatex(-10px);
      @media screen and (max-width: 600px) {
        transform: translatex(-7px);
      }
    }

    &:nth-child(3) {
      transform: translatex(-20px);
      @media screen and (max-width: 600px) {
        transform: translatex(-14px);
      }
    }

    &.checkout-steps__step--last {
      clip-path: polygon(100% 0, 100% 45%, 100% 100%, 0% 100%, 15px 50%, 0% 0%);
      transform: translatex(-30px);

      @media screen and (max-width: 600px) {
        transform: translatex(-21px);
      }

      @media screen and (max-width: 600px) {
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10px 50%, 0% 0%);
      }
    }

    &.checkout-steps__step--active {
      @apply font-bold bg-[#CCA666] relative text-dark;

      &::after {
        @apply content-[""] bg-[#FAF2E6] absolute left-px top-px w-[calc(100%_-_2px)] h-[calc(100%_-_2px)];
        clip-path: polygon(calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0% 100%, 15px 50%, 0% 0%);

        @media screen and (max-width: 600px) {
          clip-path: polygon(calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 10px 50%, 0% 0%);
        }
      }
    }

    &.checkout-steps__step--previous {
      @apply bg-[#FAF2E6] relative;

      &::after {
        @apply content-["\f00c"] font-fontAwesome text-black absolute right-7 top-1/2 -translate-y-1/2 text-15_36_12_36;
        @apply xs:hidden;
      }
    }
  }

  .checkout-steps__title {
      @apply font-fontText;
  }
</style>
