import { defineStore } from 'pinia';

export const useQuotationStore = defineStore('quotation', {
  state: () => {
      return {
        accountData: {},
        addresses: {},
        countries: [], // Maunt specific
        isQuotationStoreFilled: false,
        isLoggedIn: false,
        defaultShippingAddress: {},
      };
  },
  actions: {
		removeAddress(address) {
			this.addresses.forEach((item, key) => {
				if (item.id === address.id) {
					this.addresses.splice(key, 1);
				}
			});
		},
    updateAddressesWithOne(address) {
    	let addressEdited = false;
      this.addresses.forEach((item, key) => {
        if (item.id === address.id) {
          this.addresses[key] = address;
					addressEdited = true;
        }
      });

			if (!addressEdited) {
        address.id = this.addresses.length + 1; // Random ID for new addresses
				this.addresses.push(address);
			}
    },
    async fillPrimaryStores() {
      this.isQuotationStoreFilled = false;
      try {
        const response = await fetch('/api/dashboard_main');

        if (!response.ok) {
          throw new Error('Failed to fetch recent order data');
        }

        const result = await response.json();
        this.accountData = result.account_data;
        this.addresses = result.addresses.reverse();
        this.countries = result.countries;

        this.isQuotationStoreFilled = true;
        this.isLoggedIn = true;

        result.addresses.forEach((item, key) => {
          if (item.is_shipping) {
            this.defaultShippingAddress = item;
          }
        });
      } catch (error) {
        this.isQuotationStoreFilled = false;
      }
    },
    async fillCountries() {
      try {
        const response = await fetch('/api/get/countries');

        if (!response.ok) {
          throw new Error('Failed to fetch recent order data');
        }

        const result = await response.json();
        this.countries = result;

        this.isQuotationStoreFilled = true;
      } catch (error) {
        this.isQuotationStoreFilled = false;
      }
    },
  },
});
