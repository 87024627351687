<script setup>
  import {ref} from 'vue';
  import { useCartStore } from "../../stores/cart";
  import RBMImage from "./../../components/Utility/RBMImage.vue";

  const cartStore = useCartStore();  
</script>

<template>
  <div class="cart-aside__items" v-if="!cartStore.cartIsLoading">
    <div class="cart-aside__item" v-for="item in cartStore.cartItems">
      <div class="cart-aside__inner">
        <div class="cart-aside__image" v-if="item.product.main_image">
          <figure class="asp-base">
            <RBMImage :image="item.product.main_image"/>
          </figure>
        </div>
        <div class="cart-aside__title notranslate">
          <span v-if="item.amount > 1" class="cart-aside__quantity">{{ item.amount }}x</span>
          {{ item.product.title }}
        </div>
      </div>
    </div>
  </div>
  <div class="cart-aside__items" v-else>
    <div class="cart-aside__item">
      <div class="cart-aside__inner">
        <span class="cart-aside__image skeleton">
          <!-- -->
        </span>
        <span class="cart-aside__title--skeleton">
          <!-- -->
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../sass/elements/skeletons.scss';

.cart-aside__item {
  @apply py-4;

  &:not(:last-of-type) {
    @apply border-b border-solid border-[#E0E0E0];
  }
}

.cart-aside__inner {
  @apply flex items-center px-9 gap-x-4;
  @apply md:px-0;
}

.cart-aside__image {
  @apply w-12 h-12 flex flex-[0_0_48px];
}


.cart-aside__quantity {
  @apply font-bold mr-1;
}

.cart-aside__title {
  @apply text-15_21_14_20;
}

.cart-aside__title--skeleton {
  @apply h-[40px] w-[250px];
  @extend .skeleton;
}
</style>
