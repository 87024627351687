import { defineStore } from 'pinia';

export const useProfileStore = defineStore('dashboard', {
  state: () => {
      return {
        latestOrder: [],
        orders: [],
        ordersHash: '',
        cart: [],
        accountData: {},
        addresses: {},
        appointments: [], // Maunt specific
        devices: [], // Maunt specific
        countries: [], // Maunt specific
        isPrimaryStoreFilled: false,
      };
  },
  actions: {
		removeAddress(address) {
			this.addresses.forEach((item, key) => {
				if (item.id === address.id) {
					this.addresses.splice(key, 1);
				}
			});
		},
    updateAddressesWithOne(address) {
			let addressEdited = false;
      this.addresses.forEach((item, key) => {
        if (item.id === address.id) {
          this.addresses[key] = address;
					addressEdited = true;
        }
      });

			if (!addressEdited) {
        address.id = this.addresses.length + 1; // Random ID for new addresses
				this.addresses.push(address);
			}
    },
    updateDefaultAddress(type, address) {
      this.addresses.forEach((item, key) => {
        if (item.id === address.id) {
          if(type === 'shipping') {
            this.addresses[key].is_shipping = true;
          } else if(type === 'billing') {
            this.addresses[key].is_billing = true;
          }
        } else {
					if(type === 'shipping') {
						this.addresses[key].is_shipping = false;
					} else if(type === 'billing') {
						this.addresses[key].is_billing = false;
					}
				}
      });

      this.accountData.default_addresses[type] = address;

      if (this.accountData?.default_addresses?.billing.id === this.accountData?.default_addresses?.shipping.id) {
        this.accountData.default_addresses.equal = true;
      } else {
        this.accountData.default_addresses.equal = false;
      }
    },
    async fillPrimaryStores() {
      this.isPrimaryStoreFilled = false;

      try {
        const response = await fetch('/api/dashboard_main');

        if (!response.ok) {
          throw new Error('Failed to fetch recent order data');
        }

        const result = await response.json();
        this.orders = result.latest_orders;
        this.cart = result.cart;
        this.accountData = result.account_data;
        this.addresses = result.addresses.reverse();
        this.appointments = result.service_orders; // Maunt Specific
        this.devices = result.my_devices; // Maunt Specific
        this.countries = result.countries;

        this.isPrimaryStoreFilled = true;

      } catch (error) {
        console.error('Failed to fill dashboard data:', error);
      }
    },
    async fillOrders() {
      // Check hash
      try {
        let hashApiCall = '/api/ordersHash';
        const hashResponse = await fetch(hashApiCall);

        const hashResult = await hashResponse.json();

        if (this.ordersHash !== hashResult) {
          let apiCall = '/api/orders'

          const response = await fetch(apiCall);
          const result = await response.json();
          this.orders = result.orders;
          this.ordersHash = hashResult;

          if (!response.ok) {
            throw new Error('Failed to orders data');
          }
        }
      } catch (error) {
        console.error('Failed to fetch cart data:', error);
      }
    },
    async getOrderById(orderId) {
      let orderData = this.orders.find((order) => order.id === orderId);

      if (orderData) {
        return orderData;
      } else {
        try {
          let apiCall = `/api/order/${orderId}`;
          const response = await fetch(apiCall);

          if (!response.ok) {
            throw new Error('Failed to orders data');
          }

          const result = await response.json();

          return result.order;
        } catch (error) {
          console.error('Failed to fetch cart data:', error);
        }
      }
    },
  },
  getters: {
    getDevicesByType: (state) => (type) => {
      return state.devices.filter((device) => device.type === type);
    }
  }
});
