export const useMainService = () => {
    return {
        async post(url, method, body) {
            try {
                const response = await fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response;
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        },
        async get(url, method) {
            try {
                const response = await fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response;
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        }
    };
};
