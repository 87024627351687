<script setup>
const props = defineProps({
  eventName: String,
  data: String
});


sendDataLayers(props.eventName, JSON.parse(atob(props.data)));
</script>

<template></template>
