<script setup>
    import { onBeforeMount, ref } from "vue";

    // TODO: Get data from backend
    const data = {
        categories: [
            {
                title: "Ontdek de wereld van Fujikura",
                description: "Alles om een compleet ondergronds en bovengronds netwerk te bouwen",
                image: "/images/hardcoded-remove-folder/hardcoded-6.png",
            },
            {
                title: "Ontdek de wereld van Fremco",
                description: "Alles om een compleet ondergronds en bovengronds netwerk te bouwen",
                image: "/images/hardcoded-remove-folder/hardcoded-6.png",
            },
            {
                title: "Ontdek de wereld van Fujikura",
                description: "Alles om een compleet ondergronds en bovengronds netwerk te bouwen",
                image: "/images/hardcoded-remove-folder/hardcoded-6.png",
            }
        ]
    }

    const props = defineProps({
        title: String,
        showLink: Boolean
    });
</script>

<template>
    <section class="slider slider--highlighted-brands">
        <div class="slider__container">
            <span class="highlighted-brands__title">{{props.title}}</span>
            <div class="slider__items" v-if="data.categories.length > 0">
                <div class="slider__swiper swiper swiper--buttons">
                    <div class="slider__swiper-wrapper swiper-wrapper">
                        <div class="swiper-slide highlighted-brands__item" v-for="category in data.categories">
                            <div class="highlighted-brands__item-inner">
                                <div class="highlighted-brands__image">
                                    <div class="highlighted-brands__image-inner">
                                        <img :src="category.image" :alt="category.title">
                                    </div>
                                </div>
                                <div class="highlighted-brands__content">
                                    <span class="highlighted-brands__content-title">{{ category.title }}</span>
                                    <p class="highlighted-brands__content-description">{{ category.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="slider__buttons swiper__buttons">
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
            <div class="slider__items" v-else>
                <div class="slider__swiper swiper swiper--buttons">
                    <div class="slider__swiper-wrapper swiper-wrapper">
                        <div class="swiper-slide highlighted-brands__item" v-for="index in 3" :key="index">
                            <div class="highlighted-brands__item-inner">
                                <div class="highlighted-brands__image--skeleton">
                                    <!-- -->
                                </div>
                                <div class="highlighted-brands__content">
                                    <span class="highlighted-brands__content-title--skeleton"><!-- --></span>
                                    <p class="highlighted-brands__content-description--skeleton"><!-- --></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="slider__buttons swiper__buttons">
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
	@import '../../sass/elements/skeletons.scss';

	.highlighted-brands__container {
		@apply container;
		@apply lg:px-6;
		@apply sm:px-4;
	}

	.highlighted-brands {
		@apply overflow-x-clip overflow-y-visible;
	}

	.highlighted-brands__title {
		@apply font-fontText font-bold text-20_28_15_21 text-dark col-span-full;
	}

	.highlighted-brands__content {
		@apply px-9 pb-9 h-full flex flex-col pt-4;
		@apply sm:px-3 sm:pb-6 sm:pt-3;
	}

	.highlighted-brands__content-title {
		@apply font-fontText text-18_23_14_22 font-bold text-dark mb-2;
	}

	.highlighted-brands__content-description {
		@apply text-16_24_14_18;
	}

	.highlighted-brands__image {
		@apply relative;
	}

	.highlighted-brands__item-inner {
		@apply rounded-br rounded-bl border border-solid border-border;
	}

	.highlighted-brands__image-inner {
		@apply relative w-screen pr-[calc(100vw_-_100%)];
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6.4vw), 0 100%);
		&::before {
			@apply content-[""] aspect-[400_/_200] w-full block absolute;
			@apply xs:aspect-[283_/_145];
		}
	}

	.highlighted-brands__image--skeleton {
		@apply aspect-[230_/_120] relative rounded-t overflow-hidden pt-1 px-9;

			&::before {
				@apply content-[""] absolute inset-x-0 top-0 bottom-3 bg-lightGrey w-screen;
					clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6.4vw), 0 100%);
			}
	}

	.highlighted-brands__content-title--skeleton {
		@extend .skeleton;
		@apply h-[20px] w-[234px] inline-flex max-w-full mb-5;
		@apply sm:mb-1;
	}

	.highlighted-brands__content-description--skeleton {
		@extend .skeleton;
		@apply h-[38px] w-[270px] inline-flex max-w-full;
	}
</style>
