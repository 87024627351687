<script setup>
  import { onUnmounted, onMounted, ref, watch } from 'vue';
  import { useProfileStore } from "../stores/mainStore";
  import AddressRow from '../components/AddressRow.vue';

  import { useDialog } from "../../composables/useDialog.js";
  import AddressesDialog from "../../components/Utility/Dialogs/Addresses.vue";

  const profileStore = useProfileStore();
  const addressHeadingRef = ref(null);
  
  const newAddress = ref(false);

  const handleScroll = () => {
    if (window.matchMedia("(max-width: 900px)").matches) {
      if (addressHeadingRef.value) {
        const rect = addressHeadingRef.value.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if rect.bottom is at or below the bottom of the viewport
        if (rect.bottom >= windowHeight) {
          addressHeadingRef.value.classList.remove('dashboard-addresses__heading--no-shadow');
        } else {
          addressHeadingRef.value.classList.add('dashboard-addresses__heading--no-shadow');
        }
      }
    }
  };
  
  const openNewAddresDialog = async () => {
    const isMobile = ref(false);
    
    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    useDialog().open(AddressesDialog, { address: {}, fromDashboard: true, editMode: false, newAddress: true, countries: profileStore.countries });
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
</script>

<template>
  <section class="dashboard-header dashboard-header--overview" aria-labelledby="dashboard__title">
    <div class="dashboard-header__content">
      <!-- TO DO HARDCODED -->
      <h1 id="dashboard__title">Adressen</h1>
      <p>Bekijk hier je woon- of vestigingsadres en aflever- en factuuradres(sen). Daarnaast kun je jouw adressen aanpassen en nieuwe toevoegen.</p>
    </div>
  </section>

  <section class="dashboard-addresses" aria-labelledby="dashboard-addresses__title" ref="addressHeadingRef">
    <div class="dashboard-addresses__heading">
      <!-- TO DO HARDCODED TEXT -->
      <h2 id="dashboard-addresses__title">Jouw adressen</h2>
      <button class="btn-solid--primary-600" @click="openNewAddresDialog()" :disabled="!profileStore.isPrimaryStoreFilled" :class="{'btn-solid--primary-600-skeleton' : !profileStore.isPrimaryStoreFilled}">Nieuw adres toevoegen</button>
    </div>      
    <div class="dashboard-addresses__items">
      <template v-if="profileStore.isPrimaryStoreFilled === true">
        <AddressRow v-for="address in profileStore.addresses" :address="address" :key="address.id" />
      </template>
      <template v-else>
        <div class="dashboard-addresses--skeleton">
          <!-- -->
        </div>
        <div class="dashboard-addresses--skeleton">
          <!-- -->
        </div>
      </template>
    </div>    
  </section>
</template>


<style lang="scss" scoped>
  @import '../../../sass/elements/skeletons.scss';

  .dashboard-addresses {
    @apply xs:relative xs:grid xs:grid-cols-12 xs:gap-0;

    &.dashboard-addresses__heading--no-shadow {
      .dashboard-addresses__heading {
        @apply shadow-none;
      }
    }
  }

  .dashboard-addresses__heading {
    @apply flex justify-between items-center col-span-12;
    @apply xs:bg-white xs:sticky xs:inset-x-0 xs:bottom-0 xs:order-2 xs:shadow-cartFooter xs:-mx-4 xs:px-4 xs:py-2.5 xs:transition-all xs:duration-300;

    h2 {
      @apply xs:hidden;
    }

    button {
      @apply xs:w-full;
    }
  }

  .dashboard-addresses__items {
    @apply flex flex-col gap-3 col-span-12;
  }

  .dashboard-addresses--skeleton {
    @extend .skeleton;
    @apply w-full h-[226px] rounded;
  }

  .btn-solid--primary-600-skeleton {
    @apply opacity-50 pointer-events-none;
  }
</style>
