import { ref, onMounted, onUnmounted } from 'vue';

export function useImageZoom(image = false) {

  const zoomed = ref(false);
  const zoomContainer = ref(null);
  const originalImage = ref(null);

  const onMouseEnter = () => {
      zoomed.value = true;
      zoomIn();
    };

    const onMouseLeave = () => {
        zoomed.value = false;
        zoomOut();
    };

  const zoomIn = () => {
    if (zoomContainer.value && originalImage.value) {
      if(originalImage.value.src === undefined && originalImage.value.querySelector('img')) {
        originalImage.value = originalImage.value.querySelector('img');
      }
      const rect = originalImage.value.getBoundingClientRect();
      zoomContainer.value.style.width = `${rect.width}px`;
      zoomContainer.value.style.height = `${rect.height}px`;
      zoomContainer.value.style.backgroundImage = `url(${originalImage.value.src})`;
      zoomContainer.value.style.backgroundSize = '200%';
      zoomContainer.value.style.opacity = 100;
    }
  };

  const zoomOut = () => {
    if (zoomContainer.value) {
      zoomContainer.value.style.opacity = 0;
    }
  };

  const onMouseMove = (e) => {
    if (zoomed.value && zoomContainer.value && originalImage.value) {
      const rect = originalImage.value.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const xPercent = (x / rect.width) * 100;
      const yPercent = (y / rect.height) * 100;
      zoomContainer.value.style.backgroundPosition = `${xPercent}% ${yPercent}%`;
    }
  };

  onMounted(() => {
    originalImage.value = document.querySelector('.product-header-images__main-image img');
    if (originalImage.value) {
      originalImage.value.addEventListener('mouseenter', onMouseEnter);
      originalImage.value.addEventListener('mouseleave', onMouseLeave);
      originalImage.value.addEventListener('mousemove', onMouseMove);
    }
  });

  onUnmounted(() => {
    originalImage.value = document.querySelector('.product-header-images__main-image img');
    if (originalImage.value) {
      originalImage.value.removeEventListener('mouseenter', onMouseEnter);
      originalImage.value.removeEventListener('mouseleave', onMouseLeave);
      originalImage.value.removeEventListener('mousemove', onMouseMove);
    }
  });

  return {
    zoomed,
    zoomContainer,
    originalImage,
  };
}
