<script setup>
  import { onBeforeMount } from "vue";
  import { useWishlistStore } from "../stores/wishlist";
  const wishlistStore = useWishlistStore();
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const props = defineProps({
    wishlist: Object,
  });

  onBeforeMount(() => {
    // cartStore.fetchCart(true);
    wishlistStore.wishlist = props.wishlist;
    // wishlistStore.fetchWishlistItems();
  });

</script>

<template>
    <a class="nav__elements--wishlist" :href="appendLanguagePrefixURL('/verlanglijst')"> <!-- TODO: HARCODED URL -->
        <span class="amount" v-if="wishlistStore.wishlist && wishlistStore.wishlist.productCount > 0">
            {{ wishlistStore.wishlist.productCount }}
        </span>
        <i class="fa-light fa-heart"></i>
    </a>
</template>


<style lang="scss" scoped>
    .amount {
        @apply content-[""] absolute text-white rounded-full text-[10px] h-[20px] w-[20px] bg-primary-600 flex items-center justify-center right-[2px] top-5;
        @apply lg:top-[5px];
    }
</style>
