<script setup>
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useProfileStore } from "../stores/mainStore";
  import { formatDate } from "../../composables/formatDate";
  import RBMImage from "./../../components/Utility/RBMImage.vue";

  const profileStore = useProfileStore();

  const routeParams = useRoute().params.id;

  const order = ref();
  const formattedDate = ref({Type: String});

  onMounted(async () => {
      order.value = await profileStore.getOrderById(routeParams);
      formattedDate.value = formatDate(order.value.date);
  });

</script>

<template>

<section class="dashboard-order" aria-labelledby="dashboard-order__header-title" v-if="order">
  <div class="dashboard-order__header" >
    <div class="dashboard-order__return">
      <router-link :to="{ name: 'Bestellingen' }">Alle bestellingen</router-link>
    </div>
    <!-- TO DO HARDCODED -->
    <h1 id="dashboard-order__header-title">Details van je bestelling</h1>

    <div class="dashboard-order__header-specifications" v-if="formattedDate && order.order_number">
        <span class="dashboard-order__header-date" v-if="formattedDate">Besteld op: {{ formattedDate.value }}</span>
        <span class="dashboard-order__header-ordernumber">Ordernummer: {{ order.order_number }}</span>
        <span class="dashboard-order__header-date" v-if="order.email_track_and_trace">Track & Trace: {{ order.email_track_and_trace }}</span>
    </div>
  </div>

  <div class="dashboard-order__products" :class="{ 'dashboard-order__products--download': order.invoice_urls?.url }">
    <div class="dashboard-order__products-header">
      <h2>Jouw bestelling</h2>
      <a v-if="order.invoice_urls?.url" class="btn-text--smedium--primary-600" :href="order.invoice_urls.url" target="_blank">Download factuur</a>
    </div>

    <div class="dashboard-order__products-items" v-if="order.products">
      <article class="dashboard-order__product" v-for="product in order.products" :key="product.id">
        <div class="dashboard-order__product-content">
          <div class="dashboard-order__product-image" v-if="product.image_object">
            <figure class="asp-base">
              <RBMImage :image="product.image_object"/>
            </figure>
          </div>
          <div class="dashboard-order__product-text">
            <span class="dashboard-order__product-title notranslate">
              <strong>{{ product.quantity }}x&nbsp;</strong>
              {{ product.title }}
            </span>
          </div>
          <div class="dashboard-order__product-price">
            <span>&euro; {{ product.total_price }}</span>
          </div>
        </div>
        <a class="block-link" v-if="product.slug" :href="`/${product.slug}`"></a>
      </article>
    </div>
  </div>

  <div class="dashboard-order__informatiom">
    <div class="dashboard-order__informatiom-header">
      <h2>Adres en betaalinformatie</h2>
    </div>
    <div class="dashboard-order__informatiom-content">
      <div class="dashboard-order__informatiom-addresses">
        <div class="dashboard-order__informatiom-addres" v-if="order.delivery_address">
          <strong>Afleveradres</strong>
          <p class="notranslate">{{ order.delivery_address.name }}<br />
          {{ order.delivery_address.address }}<br />
          {{ order.delivery_address.postal_code }} {{ order.delivery_address.city }}<br />
          {{ order.delivery_address.country }}</p>
        </div>
        <div class="dashboard-order__informatiom-addres" v-if="order.invoice_address">
          <strong>Factuuradres</strong>
          <p class="notranslate">{{ order.invoice_address.name }}<br />
          {{ order.invoice_address.address }}<br />
          {{ order.invoice_address.postal_code }} {{ order.invoice_address.city }}<br />
          {{ order.invoice_address.country }}</p>
        </div>
      </div>
      <div class="dashboard-order__informatiom-fees">
        <span class="dashboard-order__informatiom-fee" v-if="order.delivery_costs"><strong>Bezorging</strong><strong>&euro;{{ order.delivery_costs }}</strong></span>
        <span class="dashboard-order__informatiom-fee" v-if="order.total_costs"><strong>Totaalbedrag</strong><strong>&euro;{{ order.total_costs }}</strong></span>
        <span class="dashboard-order__informatiom-remark">Exclusief BTW</span>
        <div class="dashboard-order__informatiom-invoice" v-if="order.invoice_urls?.url">
          <a class="btn-text--smedium--primary-600" :href="order.invoice_urls.url" target="_blank">Download factuur</a>
        </div>
      </div>
    </div>
  </div>
</section>

</template>
