import { useMainService } from './mainService';

const mainService = useMainService();

export const useCartService = () => {
    return {
        async modifyItem(itemId, quantity) {
            let url = `/api/cart/modify_item/${itemId}/${quantity}`;
            let body = { itemId, quantity };
            let method = 'POST';

            mainService.post(url, method, body)
        },

        async addItem(itemId, quantity) {
          let url = `/api/cart/add_item/${itemId}/${quantity}`;
          let body = { itemId, quantity };
          let method = 'POST';

          return await mainService.post(url, method, body);
        },

        async getRelatedProducts(itemId) {
            let url = `/api/cart/get/`;
            let method = 'GET';
            const response = await mainService.post(url, method);
        }
    };
};
