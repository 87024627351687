<script setup>
  import { ref, onMounted } from "vue";
  import { useDialog } from "../../../composables/useDialog";
  import { useProfileStore } from "@/profile/stores/mainStore";

  const props = defineProps({
    data: {}
  });

  const addressesData = ref(props.data);

	const removeAddress = () => {
		fetch('/api/address/'+ props.data.address.id + '/delete', {
			method: 'POST',
			body: JSON.stringify(props.data.address)
		})
		.then(response => response.json())
		.then(response => {
			if (response.success) {
                useProfileStore().removeAddress(props.data.address);
				useDialog().toggle();
			} else {
				// TODO Handle error
			}
		})
		.catch(error => {
		// Handle error
		});
	}

  const removeAddressTitleText = ref(removeAddressTitleTextCode);
  const removeAddressTextText = ref(removeAddressTextTextCode);
  const removeAddressCancelText = ref(removeAddressCancelTextCode);
  const removeAddressDeleteText = ref(removeAddressDeleteTextCode);
</script>

<template>
	<div class="dialog__header">
		<span class="dialog__title">{{ removeAddressTitleText }}</span> <!-- VUE_DL_REMOVE_ADDR_TITLE -->
		<button class="dialog__close" @click="useDialog().toggle">
			<i class="fa-solid fa-times"></i>
		</button>
	</div>
	<div class="dialog__content" @scroll="checkDialogPosition()">
		<p>{{ removeAddressTextText }}</p> <!-- VUE_DL_REMOVE_ADDR_TEXT -->
  </div>
	<div class="dialog__buttons">
		<button class="btn-transp--border" @click="useDialog().toggle" type="button">{{ removeAddressCancelText }}</button> <!-- VUE_DL_REMOVE_ADDR_CANCEL -->
		<a @click="removeAddress(addressesData.address)" class="btn-solid--primary-600">{{ removeAddressDeleteText }}</a> <!-- VUE_DL_REMOVE_ADDR_DELETE -->
	</div>	
</template>

<style lang="scss" scoped>
dialog {
  &[open] {
    @apply container grid grid-cols-12 gap-6 bg-transparent h-[90vh] p-0;
    @apply lg:px-6;
    @apply sm:px-4 sm:h-screen;
    @apply xs:p-0 xs:m-0 xs:max-w-full xs:h-full xs:max-h-full;

    &:focus-within {
      @apply outline-none;
    }

    &::backdrop {
      @apply bg-black/60;
      backdrop-filter: blur(2px);
    }
  }
}

.dialog__header {
  @apply relative;
}

.dialog__close {
  @apply absolute top-3 right-3 rounded-full bg-white flex items-center justify-center w-9 h-9 border border-solid border-border transition-all duration-300 text-[16px];
  @apply sm:bg-dark-950 sm:text-white sm:top-3 sm:border-none;
  @apply hover:border-primary;

  i {
    @apply sm:font-light;
  }
}

.dialog__title {
  @apply font-fontText font-bold text-19_32_17_30 bg-white text-dark pb-3 px-9 pt-6 flex;
  @apply sm:bg-dark-950 sm:text-white sm:px-5 sm:py-4;
}

.dialog__content {
  @apply bg-white overscroll-contain px-9 w-[503px];
  @apply lg:px-5 lg:pt-[30px];
  @apply sm:pb-24;
  @apply mob:col-span-12 mob:px-4;
  @apply xs:pb-20;
}

.dialog__buttons {
	@apply p-9 pt-6 grid grid-cols-12 gap-x-3;
	a, button {
		@apply col-span-6;
	}
}
</style>
