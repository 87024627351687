import { ref, onMounted, onBeforeUnmount } from 'vue';

export function useDropdowns() {
	const isUserOpen = ref(false);
	const isUserWideScreen = ref(window.innerWidth > 1225);

	const isCartWideScreen = ref(window.innerWidth > 768);
	const isCartOpen = ref(false);

	const handleUserResize = () => {
		isUserWideScreen.value = window.innerWidth > 1225;
	};

	const handleCartResize = () => {
		isCartWideScreen.value = window.innerWidth > 768;
	};

	const handleKeydown = (e) => {
		if (e.key === 'Escape') {
			isUserOpen.value = false;
			isCartOpen.value = false;
		}
	};

	const outsideClickHandler = (e) => {
		if (cartWrapper.value && !cartWrapper.value.contains(e.target) && !e.target.classList.contains('nav__elements--cart') && !e.target.classList.contains('cart__items')) {
			isUserOpen.value = false;
			isCartOpen.value = false;
		}
	};

	
	const handleUserTrigger = () => {
		const trigger = document.querySelector('.nav__elements--loggedin');
		const dropdown = document.querySelector('.nav__elements--dropdown');

		if (trigger && dropdown) {
			trigger.addEventListener('click', (event) => {
				isUserOpen.value = !isUserOpen.value;
				isCartOpen.value = false;
				event.stopPropagation();
			});

			dropdown.addEventListener('click', (event) => {
				event.stopPropagation();
			});

			document.querySelector('body').addEventListener('click', () => {
				isUserOpen.value = false;
			});
		}
	};

	const handleCartTrigger = () => {
		const trigger = document.querySelector('.nav__elements--cart');
		const dropdown = document.querySelector('.cart-wrapper--popup');

		if (trigger && dropdown) {
			trigger.addEventListener('click', (event) => {
				isCartOpen.value = !isCartOpen.value;
				isUserOpen.value = false;
				event.stopPropagation();
			});

			dropdown.addEventListener('click', (event) => {
				event.stopPropagation();
			});

			document.querySelector('body').addEventListener('click', (e) => {
				isCartOpen.value = false;
			});
		}
	}

	onMounted(() => {
		window.addEventListener('resize', handleUserResize);
		window.addEventListener('resize', handleCartResize);
		document.addEventListener('keydown', handleKeydown);
		handleUserTrigger();
		handleCartTrigger();
	});

	onBeforeUnmount(() => {
		window.removeEventListener('resize', handleUserResize);
		window.removeEventListener('resize', handleCartResize);
		document.removeEventListener('keydown', handleKeydown);
	});

	return {
		isUserOpen,
		isUserWideScreen,
		isCartOpen,
		isCartWideScreen,
	};
}