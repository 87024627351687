<script setup>
  import { onBeforeMount, computed } from "vue";
  import { useProfileStore } from "./stores/mainStore";
  import { useRoute } from 'vue-router';
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const profileStore = useProfileStore();
  const route = useRoute();

  onBeforeMount(async () => {
      profileStore.fillPrimaryStores();
  });

  const isParentActive = computed(() => {
    return route.name === 'Bestellingen' || route.name === 'Bestelling';
  });

</script>

<template>
  <!-- TO DO HARDCODED -->
  <section class="dashboard" aria-label="Dashboard overzicht">
    <aside class="dashboard-navbar">
      <nav class="dashboard-navbar__nav">
        <div>
          <router-link :to="{ name: 'Overzicht' }">Overzicht</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Bestellingen' }" :class="{ 'active': isParentActive }">Bestellingen</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Service Afspraken' }">Service afspraken</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Mijn Apparaten' }">Mijn apparaten</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Gegevens' }">Gegevens</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Adressen' }">Adressen</router-link>
        </div>
        <div>
          <a :href="appendLanguagePrefixURL('/logout')">Uitloggen</a>
        </div>
      </nav>
      <div class="dashboard-navbar__trigger">
        <button data-id='user-nav-toggle-breadcrumb'>Mijn Maunt</button>
      </div>
    </aside>

    <div class="dashboard-main">
      <router-view></router-view>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '../../sass/elements/skeletons.scss';

  .dashboard {
    @apply container grid grid-cols-12 gap-3 mb-16;
    @apply lg:px-6;
    @apply md:mb-9;
    @apply sm:px-4;

    &:has(.dashboard-order) {
      @apply sm:gap-y-1;
    }
  }

  .dashboard-navbar {
    @apply col-span-3 self-start;
    @apply lg:col-span-12;
  }

  .dashboard-main {
    @apply col-span-9 self-start;
    @apply lg:col-span-12;
  }

  .dashboard-navbar__nav {
    @apply lg:hidden;
  }

  .dashboard-navbar__trigger {
    @apply hidden;
    @apply lg:block;
  }
</style>
