import { useMainService } from './mainService';

const mainService = useMainService();

export const useWishlistService = () => {
    return {
        async removeItemFromWishlist(itemId) {
            let url = `/api/wishlist/remove_item/${itemId}`;
            let body = { itemId };
            let method = 'POST';

            return await mainService.post(url, method, body).then((response) => response.json());
        },
        async addItemToWishlist(itemId) {
            let url = `/api/wishlist/add_item/${itemId}`;
            let body = { itemId };
            let method = 'POST';

            return await mainService.post(url, method, body).then((response) => response.json());
        },
    };
};