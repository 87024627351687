<script setup>
  import { ref, onBeforeUpdate, onMounted } from 'vue';
  import { formatDate } from "../../composables/formatDate";

  const props = defineProps({
    device: Object
  });

  const maintenanceNotice = ref(false);

  let purchaseDate = ref(props.device.purchase_date ? formatDate(props.device.purchase_date) : null);
  let lastMaintenanceDate = ref(formatDate(props.device.last_maintenance));
  let nextMaintenanceDate = ref(formatDate(props.device.next_maintenance));

  const showMaintenanceNotice = () => {
    let currentDate = new Date();
    let dateToCompare = new Date(props.device.next_maintenance);

    if (!isNaN(dateToCompare) && Math.ceil((dateToCompare - currentDate) / (1000 * 60 * 60 * 24)) <= 14) {
      maintenanceNotice.value = true;
    } else {
      maintenanceNotice.value = false;
    }
  }

  onBeforeUpdate(() => {
    purchaseDate = props.device.purchase_date ? formatDate(props.device.purchase_date) : null;
    lastMaintenanceDate = formatDate(props.device.last_maintenance);
    nextMaintenanceDate = formatDate(props.device.next_maintenance);

    showMaintenanceNotice();
  });

  onMounted(() => {
    showMaintenanceNotice();
  });
</script>

<template>
  <article class="dashboard-device">
    <div class="dashboard-device__item">
      <div>
        <div>
          <span class="dashboard-device__notice" v-if="maintenanceNotice">Heeft binnen 2 weken onderhoud nodig</span>
          <div class="dashboard-device__details">
              <span class="dashboard-device__title">{{ device.title }}</span>
              <span class="dashboard-device__number">{{ device.product_number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-device__purchasedate">
      <div>
        <div>
          <span class="dashboard-device__label">Aankoopdatum:</span>
          <span v-if="purchaseDate">{{ purchaseDate }}</span>
          <span v-else>
            <i class="fa-solid fa-circle-exclamation" v-tooltip="{ value: 'Geen geldige datum gevonden', showDelay: 300, hideDelay: 300 }"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="dashboard-device__lastmaintenance">
      <div>
        <div>
          <span class="dashboard-device__label">Laatste onderhoud:</span>
          <span v-if="lastMaintenanceDate">{{ lastMaintenanceDate }}</span>
          <span v-else>
            <i class="fa-solid fa-circle-exclamation" v-tooltip="{ value: 'Geen geldige datum gevonden', showDelay: 300, hideDelay: 300 }"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="dashboard-device__nextmaintenance">
      <div>
        <div>
          <span class="dashboard-device__label">Onderhoud nodig:</span>
          <span v-if="nextMaintenanceDate">{{ nextMaintenanceDate }}</span>
          <span v-else>
            <i class="fa-solid fa-circle-exclamation" v-tooltip="{ value: 'Geen geldige datum gevonden', showDelay: 300, hideDelay: 300 }"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="dashboard-device__action">
      <div>
        <a href="https://servicetool.maunt.com/service/afspraak-maken" target="_blank" class="btn-solid--primary-600">Service afspraak</a> <!-- HARDCODED -->
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
  .dashboard-device {
    @apply table-row;
    @apply md:grid;

    > div {
      @apply table-cell align-middle;
      @apply md:block;

      > div {
        @apply h-full flex items-center ;
      }

      &.dashboard-device__item {
        @apply w-full;
      }

      &.dashboard-device__action {
        .btn-solid--primary-600 {
          @apply whitespace-nowrap;
        }
      }
    }
  }

  .dashboard-device__label {
    @apply hidden;
    @apply md:block;
  }
</style>
