<script setup>
  import { ref, watch, onMounted } from 'vue';
  import RBMImage from "./RBMImage.vue";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  let dialog = ref(null);

  const props = defineProps({
    title: String,
    showLink: Boolean,
    products: Array,
    product: Object,
    related: Array,
    isOpen: Boolean,
    dialogId: String
  });

  const emit = defineEmits(['close']);

  watch(() => props.isOpen, (newVal) => {
    if (newVal) {
        dialog.value.showModal();
    } else {
        dialog.value.close();
    }
  });

  onMounted(() => {
  dialog.value = document.getElementById(props.dialogId); // Use the unique ID
});

  const closeDialog = () => {
    dialog.value.close();
    emit('close');
  };

  const handleDialogClick = (event) => {
    if (event.target === dialog.value) {
      closeDialog();
    }
  };

</script>

<template>
  <dialog :id="props.dialogId" @click="handleDialogClick($event)">
    <div class="dialog__content">
      <div class="dialog__header">
        <div class="dialog__header--content">
          <!-- TO DO HARDCODED TEXT -->
          <span class="dialog__title">Het product is toegevoegd aan je winkelwagen</span>
          <div class="dialog__header--product">
            <i class="fa-solid fa-circle-check"></i>
            <figure class="asp-base dialog__header--product-image" v-if="product">
              <RBMImage :image="props.product.images[0]" />
            </figure>
            <p v-if="product" class="notranslate">{{product.title}}</p>
          </div>
        </div>
        <div class="dialog__header--buttons">
          <a :href="appendLanguagePrefixURL('/afrekenen')" class="btn-solid--primary-600">Verder naar bestellen</a>
          <p class="dialog__header--continue">of <a class="close-button" @click="closeDialog(dialog)">verder winkelen</a></p>
        </div>
      </div>
      <div class="dialog__products" v-if="related">
        <p class="dialog__products--title">Vaak samen gekocht</p>
        <ProductCard :isPopular="false" v-for="product in related" :product="product" :showWishlist="false" />
      </div>
      <div class="dialog__buttons">
        <button class="btn-transp--border" @click="closeDialog(dialog)">Verder winkelen</button>
        <a :href="appendLanguagePrefixURL('/afrekenen')" class="btn-solid--primary-600">Verder naar bestellen</a>
      </div>
    </div>
  </dialog>
</template>

<style lang="scss" scoped>
  dialog {
    &[open] {
      @apply container grid grid-cols-12 gap-6 bg-transparent h-[90vh] p-0;
      @apply lg:px-6;
      @apply sm:px-4;
      @apply xs:p-0 xs:m-0 xs:max-w-full xs:h-full xs:max-h-full;

      &:focus-within {
        @apply outline-none;
      }

      &::backdrop {
        @apply bg-black/60;
        backdrop-filter: blur(2px);
      }
    }
  }

  .dialog__content {
    @apply col-span-8 col-start-3 h-full overflow-y-auto bg-white overscroll-contain;
    @apply mob:col-span-12;
  }

  .dialog__header {
    @apply grid grid-cols-12 gap-x-[28px] bg-lightGold-50 p-9;
    @apply sm:pt-[18px] sm:pb-[18px] sm:gap-x-[unset];

    .dialog__header--content {
      @apply col-span-8;
      @apply lg:col-span-7;
      @apply md:col-span-12;
    }

    .dialog__header--buttons {
      @apply col-span-4 flex flex-col;
      @apply lg:col-span-5;
      @apply md:col-span-12;
    }

    .btn-solid--primary-600, .dialog__header--continue {
      @apply col-span-4;
    }

    .dialog__header--continue {
      @apply col-start-9 text-center mt-[18px];
      @apply md:text-left;

      .close-button {
        @apply cursor-pointer;
      }
    }

    .btn-solid--primary-600 {
      @apply row-start-1;
    }
  }

  .dialog__title {
    @apply col-span-8 row-start-1 font-fontText font-bold text-20_28_15_21 text-dark;
  }

  .dialog__header--product {
    @apply col-span-8 flex items-center gap-x-[18px] relative;
    p {
      @apply text-16_24_14_18;
    }

    i {
      @apply absolute inset-0 top-[25%] text-green;
    }
  }

  .dialog__header--product-image {
    @apply w-16 h-16;
  }

  .dialog__products--title {
    @apply col-span-12 font-fontText font-bold text-20_28_15_21 text-dark;
  }

  .dialog__products {
    @apply grid grid-cols-12 gap-x-9 p-9 pt-6 border-b border-border max-w-[800px];
    @apply lg:px-6;
    @apply sm:px-4 sm:gap-x-[18px];

    .product__item {
      @apply col-span-4;
      @apply md:col-span-6;
    }

    .product__item-content {
      @apply flex h-full flex-col;
    }
  }

  .dialog__buttons {
    @apply p-9 pt-6 grid grid-cols-12 gap-x-3;
    a, button {
      @apply col-span-6;
    }
  }


</style>
