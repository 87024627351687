export function filterActions() {
    const filterButtons = document.querySelectorAll(".filters__button");

    if (filterButtons) {
        filterButtons.forEach((button) => {
            let filterNavigation = button.nextElementSibling;

            if (filterNavigation.classList.contains('filters__navigation')) {
                button.addEventListener('click', function () {
                    showFilter(filterNavigation);
                });

                filterNavigation.querySelector('.filters__navigation-head').addEventListener('click', function (e) {
                    e.stopPropagation();
                });

                filterNavigation.querySelector('.filters__navigation-content').addEventListener('click', function (e) {
                    e.stopPropagation();
                });

                filterNavigation.querySelector('.filters__navigation-closer').addEventListener('click', function () {
                    closeFilter(filterNavigation);
                });

                document.addEventListener("click", function () {
                    closeFilter(filterNavigation);
                });

                document.addEventListener('keydown', function(event) {
                    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
                        closeFilter(filterNavigation);
                    }
                });
            }
        });
    }
}

export function filtersResize() {
    if (window.matchMedia("(min-width: 901px)").matches) {
        let activeFilterNavigations = document.querySelectorAll('.filters__navigation.filters__navigation--active');

        if (activeFilterNavigations) {
            activeFilterNavigations.forEach((navigation) => {
                closeFilter(navigation);
            });
        }
    }
}

export function showFilter(filterNavigation) {
    if (filterNavigation) {
        filterNavigation.classList.add('filters__navigation--active');
        event.stopPropagation();
    }
}

export function closeFilter(filterNavigation) {
    if (filterNavigation) {
        filterNavigation.classList.remove('filters__navigation--active');
    }
}

// Attach event listeners
window.addEventListener("resize", () => {
    filtersResize();
});

window.addEventListener("orientationchange", () => {
    filtersResize();
});
