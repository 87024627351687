<script setup>
  import AppointmentRow from "../components/AppointmentRow.vue";
  import DataView from "primevue/dataview";
  import { useProfileStore } from "../stores/mainStore";
  const profileStore = useProfileStore();


  const paginationBehaviour = () => {
    const productsItems = document.getElementById('dashboard-appointments__items');

    if (productsItems) {
      const topPosition = productsItems.getBoundingClientRect().top + window.scrollY - 200;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  }
</script>

<template>
  <section class="dashboard-header dashboard-header--appointments" aria-labelledby="dashboard__title">
    <div class="dashboard-header__content">
      <!-- TO DO HARDCODED -->
      <h1 id="dashboard__title">Service afspraken</h1>
      <!-- <p>Lorem ipsum dolor sit amet consectetur adisciping elit maecanas viverra.</p> -->
    </div>
  </section>

  <section class="dashboard-appointments" aria-labelledby="dashboard-appointments__title">
    <div class="dashboard-appointments__heading">
      <!-- TO DO HARDCODED TEXT -->
      <h2 id="dashboard-appointments__title">Jouw service afspraken</h2>
      <a href="https://servicetool.maunt.com/service/afspraak-maken" target="_blank" class="btn-solid--primary-600">Nieuwe service afspraak</a>
    </div>
    <div class="dashboard-appointments__items" id="dashboard-appointments__items" v-if="profileStore.appointments?.length > 0">
      <DataView
        :value="profileStore.appointments"
        paginator
        :paginatorTemplate="'PrevPageLink PageLinks NextPageLink'"
        :rows="20"
        :alwaysShowPaginator="false"
        unstyled
        @page="paginationBehaviour"
      >
        <template #list="slotProps">
          <AppointmentRow
            v-for="appointment in slotProps.items"
            :appointment="appointment"
          />
        </template>
      </DataView>
    </div>
    <div v-else-if="!profileStore.isPrimaryStoreFilled" class="dashboard-orders">
      <div class="dashboard-skeleton--block"><!-- --></div>
      <div class="dashboard-skeleton--block"><!-- --></div>
      <div class="dashboard-skeleton--block"><!-- --></div>
    </div> 
    <div v-else>
      <span class="">Geen service afspraken gevonden</span>
    </div>  
  </section>
</template>


<style lang="scss" scoped>
  @import '../../../sass/elements/skeletons.scss';

  .dashboard-appointments__heading {
    @apply flex justify-between items-center flex-wrap gap-y-2 gap-x-4;
  }

  .dashboard-skeleton--block {
    @extend .skeleton;
    @apply h-[202px] w-full rounded;
    @apply xs:h-[169px];

    + .dashboard-skeleton--block {
      @apply mt-3;
      @apply xs:mt-3;
    }
  }
</style>
