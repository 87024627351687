<!-- App.vue -->
<script setup>
  import { useForm } from '@tanstack/vue-form'
  import { ref, onBeforeMount } from 'vue'
  import { useAddressesStore } from "@/stores/addresses";
  import { useProfileStore } from "@/profile/stores/mainStore";
  import { useQuotationStore } from "@/stores/quotation";
  import {useDialog} from "../../../composables/useDialog";

  const props = defineProps({
    address: Object,
    countries: Array,
    editMode: Boolean,
    fromDashboard: Boolean,
  });

  onBeforeMount(async () => {
    useQuotationStore().fillPrimaryStores();
  });

  const countries = ref(props.countries);

  const succesNotification = ref(false);
  const succesNotificationNew = ref(false);

  const emit = defineEmits(['successEvent, success, go, cancelEditAddress']);

  const form = useForm({
    defaultValues: {
      title: props.address?.title ?? '',
      id: props.address?.id ?? 0,
      first_name: props.address?.first_name ?? '',
      last_name: props.address?.last_name ?? '',
      company_name: props.address?.company_name ?? '',
      street: props.address?.street ?? '',
      house_number: props.address?.house_number ?? '',
      house_number_addition: props.address?.house_number_addition ?? '',
      zip: props.address?.zip ?? '',
      city: props.address?.city ?? '',
      country_id: props.address?.country_id,
      is_billing: props.address?.is_billing,
      is_shipping: props.address?.is_shipping,
    },
    onSubmit: async ({ value }) => {
      let url = '/api/address/'+ props.address?.id + '/edit';

      if(!props.editMode) {
        url = '/api/address/add';
      }

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(value)
      })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          if(props.editMode) {
              succesNotification.value = true;
              setTimeout(() => {
                scrollElmView('#notification', 250);
              }, 1000);            
          } else {
            succesNotification.value = false;
            succesNotificationNew.value = true;
          }
          
          // useAddressesStore().updateAddressesWithOne(value);
          // useProfileStore().updateAddressesWithOne(value);
          // useQuotationStore().updateAddressesWithOne(value);

          useAddressesStore().fillPrimaryStores();
          useProfileStore().fillPrimaryStores();
          useQuotationStore().fillPrimaryStores();
          useDialog().toggle();
        } else {
          // TODO Handle error
        }
      })
      .catch(error => {
        // Handle error
      });
    },
  })

  const newAdressNotiEditSuccessText = ref(newAdressNotiEditSuccessTextCode);
  const newAdressNotiNewSuccessText = ref(newAdressNotiNewSuccessTextCode);
  const newAdressHowNameText = ref(newAdressHowNameTextCode);

  const obligatoryFieldText = ref(obligatoryFieldTextCode);

  const firstNameLabelText = ref(firstNameLabelTextCode);
  const lastNameLabelText = ref(lastNameLabelTextCode);
  const companyLabelText = ref(companyLabelTextCode);
  const phoneNumberLabelText = ref(phoneNumberLabelTextCode);
  const emailLabelText = ref(emailLabelTextCode);
  const streetLabelText = ref(streetLabelTextCode);
  const houseNumberLabelText = ref(houseNumberLabelTextCode);
  const additionLabelText = ref(additionLabelTextCode);
  const postalCodeLabelText = ref(postalCodeLabelTextCode);
  const cityLabelText = ref(cityLabelTextCode);
  const invoiceAddressLabelText = ref(invoiceAddressLabelTextCode);
  const sameAsShippingLabelText = ref(sameAsShippingLabelTextCode);
  const selectCountryText = ref(selectCountryTextCode);
  const countryLabelText = ref(countryLabelTextCode);
  const accountCreationLabelText = ref(accountCreationLabelTextCode);
  const accountCreationCheckboxText = ref(accountCreationCheckboxTextCode);
  const passwordLabelText = ref(passwordLabelTextCode);
  const passwordConfirmationLabelText = ref(passwordConfirmationLabelTextCode);

  const removeAddressCancelText = ref(removeAddressCancelTextCode);
  const removeAddressSubmitText = ref(newEditAddressButtonTextTextCode);
</script>

<template>
  <section class="checkout-address" aria-label="Vul hier je gegevens in">
    <div class="form__wrapper">
      <div id="notification" v-if="succesNotification" class="form__notification form__notification--success">
        <p>{{ newAdressNotiEditSuccessText }}</p> <!-- VUE_DL_NEW_ADDR_NOTI_EDIT_SUCCESS -->
      </div>      
      <div id="notification" v-if="succesNotificationNew" class="form__notification form__notification--success">
        <p>{{ newAdressNotiNewSuccessText }}</p> <!-- VUE_DL_NEW_ADDR_NOTI_NEW_SUCCESS -->
      </div>      
      <form @submit="
      (e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }
      ">
        <div class="input--full">
          <div class="input-inner">
            <label for="title" class="form__label">{{ newAdressHowNameText }}</label> <!-- VUE_DL_EDIT_ADDR_HOW_NAME -->
            <form.Field
              name="title"
              :validators="{
                onChange: ({ value }) =>
                  !value
                    ? obligatoryFieldText
                    : undefined,
                }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input">
          <div class="input-inner">
            <label for="first_name" class="form__label">{{ firstNameLabelText }}</label> <!-- VUE_CO_NA_LABEL_FIRSTNAME -->
            <form.Field
              name="first_name"
              :validators="{
                onChange: ({ value }) =>
                  !value
                    ? obligatoryFieldText
                    : undefined,
                }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input">
          <div class="input-inner">
            <label for="last_name" class="form__label">{{ lastNameLabelText }}</label> <!-- VUE_CO_NA_LABEL_LASTNAME -->
            <form.Field
              name="last_name"
              :validators="{
                onBlur: ({ value }) => !value ? obligatoryFieldText : null,
              }"
              >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single" v-if="editMode">
          <div class="input-inner">
            <label for="company_name" class="form__label">{{ companyLabelText }}</label> <!-- VUE_CO_NA_LABEL_COMPANY -->
            <form.Field
              name="company_name"
            >
              <template v-slot="{ field }">
                <input
                disabled
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="country_id" class="form__label">{{ countryLabelText }}</label> <!-- VUE_CO_NA_LABEL_COUNTRY -->
            <form.Field name="country_id"
              :validators="{
                onBlur: ({ value }) => !value ? obligatoryFieldText : null,
              }">
              <template v-slot="{ field }">

                <select
                  :name="field.name"
                  :value="field.state.value"
                  @blur="field.handleBlur"
                  @input="(e) => {
                    field.handleChange(e.target.value);
                  }"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                >
                  <option value="" disabled>{{ selectCountryText }}</option> <!-- VUE_CO_NA_SELECT_COUNTRY -->
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                  <!-- Add more countries here -->
                </select>
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>

        <div class="input input--address">
          <div class="input-inner input-inner--col-6">
            <label for="street" class="form__label">{{ streetLabelText }}</label>
            <form.Field
              name="street"
              :validators="{
                onBlur: ({ value }) => !value ? obligatoryFieldText : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner">
            <label for="house_number" class="form__label">{{ houseNumberLabelText }}</label>
            <form.Field
              name="house_number"
              :validators="{
                onBlur: ({ value }) => !value ? obligatoryFieldText : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="number"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner">
            <label for="house_number_addition" class="form__label">{{ additionLabelText }}</label>
            <form.Field
              name="house_number_addition"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--city">
          <div class="input-inner ">
            <label for="zip" class="form__label">{{ postalCodeLabelText }}</label>
            <form.Field
              name="zip"
              :validators="{
                onBlur: ({ value }) => !value ? obligatoryFieldText : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner input-inner--col-6">
            <label for="city" class="form__label">{{ cityLabelText }}</label>
             <form.Field
              name="city"
              :validators="{
                onBlur: ({ value }) => !value ? obligatoryFieldText : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
<!-- 
        <div class="input input--single" :class="{ 'input--hidden' : props.fromDashboard }">
          <div class="input-inner">
            <div class="input-inner__checkbox">
              <label for="is_default_shipping_address" class="input-container">
                <form.Field name="is_shipping">
                  <template v-slot="{ field }">
                    <input
                      type="checkbox"
                      :name="field.name"
                      :value="field.state.value"
                      @blur="field.handleBlur"
                      id="is_default_shipping_address"
                      @input="field.handleChange($event.target.checked)"
                      class="checkbox__fix-input"
                      :checked="field.state.value"
                    >
                    <span class="checkbox__fix-checkbox"></span>
                    <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
                  </template>
                </form.Field>
                Stel in als standaard bezorgadres</label>
            </div>
          </div>
        </div>

        <div class="input input--single" :class="{ 'input--hidden' : props.fromDashboard }">
          <div class="input-inner">
            <div class="input-inner__checkbox">
              <label for="is_default_billing_address" class="input-container">
                <form.Field name="is_billing">
                  <template v-slot="{ field }">
                    <input
                      type="checkbox"
                      :name="field.name"
                      :value="field.state.value"
                      @blur="field.handleBlur"
                      id="is_default_billing_address"
                      @input="field.handleChange($event.target.checked)"
                      class="checkbox__fix-input"
                      :checked="field.state.value"
                    >
                    <span class="checkbox__fix-checkbox"></span>
                    <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
                  </template>
                </form.Field>
                Stel in als standaard factuuradres</label>
            </div>
          </div>
        </div> -->
				<div class="form__submit">
					<div class="form__submit-inner">
						<div class="form__submit-cancel">
							<button class="btn-transp--border" @click="$emit('cancelEditAddress')" type="button">{{ removeAddressCancelText }}</button> <!-- TO DO HARDCODED TEXT -->
						</div>
						<div class="form__submit-button" @click="handleFormSubmit">
							<button class="btn-solid--primary-600">
								{{ removeAddressSubmitText }}
							</button>
						</div>
					</div>
				</div>
      </form>
    </div>
  </section>
</template>

  <style lang="scss" scoped>
  .checkout-address {
    @apply col-span-8;
    @apply md:col-span-12;
  }

  .checkout-address__title {
    @apply font-bold text-24_28_18_26 text-dark mb-6 col-span-12 block;
    @apply sm:mb-4;
  }

  .form__wrapper {
    form {
      @apply grid grid-cols-12 gap-x-3;
      @apply sm:gap-x-1;
    }
  }

  .password__field {
    @apply relative;
  }

  input {
    &.input--invalid {
      @apply border-red-500;
    }
  }

  .input {
    @apply col-span-6;

    &:not(:last-child) {
      @apply mb-3;

      &.input--city {
        @apply mb-9;
        @apply sm:mb-6;
      }
    }

    &:has(+ .checkout-address__title) {
      @apply mb-[60px];
      @apply md:mb-9;
      @apply sm:mb-6;
    }

    &.input--full {
      @apply mb-[30px];
      @apply mob:mb-0;
    }

    .input-inner {
      @apply col-span-12;

      span[role="alert"] {
        @apply text-13_18_11_14;
      }
    }

    .form__checkbox {
      span {
        @apply block;
      }

      label {
        @apply text-15_24_13_18 text-black font-normal;
      }
    }
  }

	.input--full {
			@extend .input;
			@apply col-span-12;
	}

  .input--single {
    @apply col-span-12 grid grid-cols-12 gap-x-3;
    @apply sm:gap-x-1;

    .input-inner {
      @apply col-span-6;
      @apply sm:col-span-12;
    }

    &:has(+.input--full) {
      @apply mb-12;
      @apply md:mb-9;
      @apply sm:mb-6;
    }
  }

  .input--hidden {
    @apply hidden;
  }

  .input--address, .input--city {
    @apply col-span-12 grid grid-cols-12 gap-x-3;
    @apply sm:gap-x-1;

    .input-inner {
      @apply col-span-3;

      &.input-inner--col-6 {
        @apply col-span-6;
      }
    }
  }

  .input--city {
    .input-inner {
      &.input-inner--col-6 {
        @apply sm:col-span-9;
      }
    }
  }

  .input--full {
    @apply col-span-12;

    .input-inner {
      @apply col-span-12;
    }
  }

	.input-inner__checkbox {
		label {
			@apply gap-x-1.5;
		}
	}

  .form__submit {
    @apply col-span-12;
  }

  .form__submit-inner {
    @apply flex justify-between items-center mt-6 gap-3;
    @apply sm:flex-col sm:items-start;
  }

  .form__submit-login {
    @apply text-15_36_14_18;
    a {
      @apply font-normal text-15_36_14_18 text-[#BF8A30];
      @apply xs:w-auto xs:inline-flex;
    }
  }

  .form__submit-button {
    @apply hover:cursor-pointer;
    @apply mob:w-full;
  }

  .form__submit-cancel,
  .form__submit-button {
    @apply w-1/2;
    @apply sm:w-full;

    button {
      @apply w-full;
    }
  }
</style>
