<!-- App.vue -->
<script setup>
import { useForm } from '@tanstack/vue-form'
import { watch, watchEffect, ref, shallowRef, reactive, onMounted } from 'vue';

import { useProfileStore } from "../stores/mainStore";
const profileStore = useProfileStore();

const succesNotification = ref(false);

const findCountry = (id) => {
  return props.data?.countries.find((country) => country.id == id);
}

const props = defineProps({
  data: Object
});

const emit = defineEmits(['success, go']);

const defaultValuesObject = ref({
  first_name: profileStore.accountData.first_name,
  last_name: profileStore.accountData.last_name,
  contact_name: profileStore.accountData.contact_name,
  phone_number: profileStore.accountData.phone_number,
  email_address: profileStore.accountData.email_address,
  password: '',
  password_confirm: '',
});

watch(
  () => profileStore.accountData,
  (newVal) => {
    if (newVal) {
      defaultValuesObject.value = {
        first_name: profileStore.accountData.first_name,
        last_name: profileStore.accountData.last_name,
        contact_name: profileStore.accountData.contact_name,
        phone_number: profileStore.accountData.phone_number,
        email_address: profileStore.accountData.email_address,
        password: '',
        password_confirm: '',
      };

      initializeForm();
    }
  }
);

const form = shallowRef(null);

const initializeForm = () => {
  form.value = useForm({
    defaultValues: defaultValuesObject.value,
    onSubmit: async ({ value }) => {
      fetch('/api/user/info/edit', {
        method: 'POST',
        body: JSON.stringify(value)
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            succesNotification.value = true;
            setTimeout(() => {
              scrollElmView('#notification', 250);
            }, 1000);
            profileStore.fillPrimaryStores();
          } else {
            succesNotification.value = false;
          }
        })
        .catch(error => {
          // Handle error
        });
    },
  })
}

onMounted(() => {
  initializeForm();
});

initializeForm();
</script>

<template>
  <section class="dashboard-header dashboard-header--overview" aria-labelledby="dashboard__title">
    <div class="dashboard-header__content">
      <!-- TO DO HARDCODED -->
      <h1 id="dashboard__title">Mijn gegevens aanpassen</h1>
      <p>Hier vind je al jouw persoonlijke gegevens. Deze gegevens kun je ten alle tijden aanpassen en aanvullen.</p>
    </div>
  </section>

  <section class="dashboard-addresses" aria-labelledby="dashboard-addresses__title" ref="addressHeadingRef">
    <div class="dashboard-addresses__heading">
      <!-- TO DO HARDCODED TEXT -->
      <h2 id="dashboard-addresses__title">Persoonlijke gegevens</h2>
    </div>
  </section>

  <div id="notification" v-if="succesNotification" class="form__notification form__notification--success">
    <p>Je gegevens zijn succesvol aangepast</p>
  </div>

  <section class="checkout-address" aria-label="Vul hier je gegevens in" v-if="profileStore.isPrimaryStoreFilled">    
    <div class="form__wrapper">
      <form @submit="
      (e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }
      "> 
        <div class="input">
          <div class="input-inner">
            <label for="first_name" class="form__label">Voornaam*</label>
            <form.Field 
              name="first_name"
              :validators="{
                onChange: ({ value }) =>
                  !value
                    ? `Dit veld is verplicht`
                    : undefined,
                }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"/>
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input">
          <div class="input-inner">
            <label for="last_name" class="form__label">Achternaam*</label>
            <form.Field 
              name="last_name"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
              >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="contact_name" class="form__label">Bedrijfsnaam*</label>
            <form.Field 
              name="contact_name"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                disabled
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="phone_number" class="form__label">Telefoonnummer*</label>
            <form.Field 
              name="phone_number"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>

        <span class="checkout-address__title">
          Inloggegevens
        </span>

        <div class="input input--single">
          <div class="input-inner">
            <label for="email_address" class="form__label">E-mailadres*</label>
            <form.Field 
              name="email_address"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="email"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                disabled
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>

        <div class="input input--single">
          <div class="input-inner">
            <label for="password" class="form__label">Wachtwoord</label>
            <form.Field 
              name="password"
            >
              <template v-slot="{ field }">
                <input
                  type="password"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">          
          <div class="input-inner">
            <label for="password" class="form__label">Herhaal wachtwoord</label>
            <form.Field 
              name="password_confirm"
              :validators="{
                onSubmit: ({ value, fieldApi }) => !value && fieldApi.form.getFieldValue('password') !== '' ? 'Dit veld is verplicht indien wachtwoord is gevuld' : null,
                onChangeListenTo: ['password'],
                onBlur: ({ value, fieldApi }) => {
                          if (value && value !== fieldApi.form.getFieldValue('password')) {
                              return 'Wachtwoorden komen niet overeen'
                          }
                          return undefined
                        }
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="password"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>

        <div class="form__submit">
          <div class="form__submit-inner">
            <div class="form__submit-button">
              <button type="submit" class="btn-solid--primary-600">
                Wijzigingen opslaan
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
  <div v-else-if="!profileStore.isPrimaryStoreFilled" class="checkout-address">
    <div class="dashboard-skeleton--block"><!-- --></div>
  </div>   
</template>
  <style lang="scss" scoped>
  @import '../../../sass/elements/skeletons.scss';
  
  .checkout-address {
    @apply col-span-8;
    @apply md:col-span-12;
  }
  
  .checkout-address__title {
    @apply font-bold text-24_28_18_26 text-dark mb-6 col-span-12 block;
    @apply sm:mb-4;
  }
  
  .form__wrapper {
    form {
      @apply grid grid-cols-12 gap-x-3;
      @apply sm:gap-x-1;
    }
  }
  
  .password__field {
    @apply relative;
  }

  input {
    &.input--invalid {
      @apply border-red-500;
    }
  }
  
  .input {
    @apply col-span-6;
    
    &:not(:last-child) {
      @apply mb-6;
      @apply sm:mb-3;
    }
    
    &:has(+ .checkout-address__title) {
      @apply mb-[60px];
      @apply md:mb-9;
      @apply sm:mb-6;
    }
    
    &.input--full {
      @apply mb-[30px];
      @apply mob:mb-0;
    }
    
    .input-inner {
      @apply col-span-12;

      span[role="alert"] {
        @apply text-13_18_11_14;
      }
    }
    
    .form__checkbox {
      span {
        @apply block;
      }
      
      label {
        @apply text-15_24_13_18 text-black font-normal;
      }
    }
  }
  
  .input--single {
    @apply col-span-12 grid grid-cols-12 gap-x-3;
    @apply sm:gap-x-1;
    
    .input-inner {
      @apply col-span-6;
      @apply sm:col-span-12;
    }
    
    &:has(+.input--full) {
      @apply mb-12;
      @apply md:mb-9;
      @apply sm:mb-6;
    }
  }
  
  .billing-address {
    @apply col-span-12;
    
    .input--city {
      @apply mb-12;
      @apply md:mb-9;
      @apply sm:mb-6;
    }
  }
  
  .input--address, .input--city {
    @apply col-span-12 grid grid-cols-12 gap-x-3;
    @apply sm:gap-x-1;
    
    .input-inner {
      @apply col-span-3;
      
      &.input-inner--col-6 {
        @apply col-span-6;
      }
    }
  }
  
  .input--city {
    .input-inner {
      &.input-inner--col-6 {
        @apply sm:col-span-9;
      }
    }
  }
  
  .input--full {
    @apply col-span-12;
    
    .input-inner {
      @apply col-span-12;
    }
  }
  
  .form__submit {
    @apply col-span-12 mt-5;
  }
  
  .form__submit-inner {
    @apply flex justify-between items-center;
    @apply sm:flex-col sm:items-start;
  }
  
  .form__submit-login {
    @apply text-15_36_14_18;
    a {
      @apply font-normal text-15_36_14_18 text-[#BF8A30];
      @apply xs:w-auto xs:inline-flex;
    }
  }
  
  .form__submit-button {
    @apply hover:cursor-pointer;
    @apply mob:w-full;
  }
  
  .dashboard-skeleton--block {
    @extend .skeleton;
    @apply h-[500px] w-full mt-3;
  }
</style>
