import { defineStore } from 'pinia';
import { useWishlistService } from '../services/wishlistService';

export const useWishlistStore = defineStore('wishlist', {
  // TODO: add back local storage functionality
  state: () => {
    return {
      wishlist: {},
      wishlistLoaded: false,
      wishlistIsLoading: false,
    };
  },
  actions: {
    checkForExistingItem(id) {
      return this.items.some((i) => i.id === id);
    },

    async deleteItemInWishlist(item) {
      this.wishlist = await useWishlistService().removeItemFromWishlist(item.id);
    },

    async addItemToWishlist(item) {
      this.wishlist = await useWishlistService().addItemToWishlist(item.id);
    },
  },

  getters: {
    findWishlistProductById(state) {
      return (id) => state.wishlist.products.find((product) => product.id === id);
    },

    productCount: (state) => state.wishlist.productCount,
  },
});
