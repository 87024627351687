import { computed } from 'vue';

export function formatDate(dateToFormat) {
  const dateObject = new Date(dateToFormat);

  const format = computed(() => {
    if (isNaN(dateObject)) {
      return null;
    } else {
      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = dateObject.getFullYear();
      return `${day}-${month}-${year}`;
    }
  });

  return format;
}
