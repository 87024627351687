<script setup>
    import { useReCaptcha } from 'vue-recaptcha-v3';
    import { onUnmounted, onMounted } from 'vue';

    const model = defineModel();
    const { recaptchaLoaded, executeRecaptcha, instance } = useReCaptcha();

    recaptchaLoaded().then(async() => {
      model.value = await executeRecaptcha('login');
    });

    const refresh = async () => {
      model.value = await executeRecaptcha('login');
    }

    defineExpose({
	  refresh,
    });

    onUnmounted(() => {
      instance.value.hideBadge();
    })
    onMounted(() => {
      instance.value.showBadge();
    })
</script>

<template>
    <input
        id="recaptcha_response"
        name="recaptcha_response"
        type="hidden"
        class="recaptcha-holder"
        required=""
        :value="model"
    />
</template>

<style scoped lang="scss">

</style>