<script setup>
  import { onMounted, onBeforeMount, ref, onBeforeUnmount, computed } from "vue";

  import { useDropdowns } from '../composables/useDropdowns';

  import { useCartStore } from "../stores/cart";
  import RBMImage from "./Utility/RBMImage.vue";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const { isCartOpen, isCartWideScreen, isUserOpen, isUserWideScreen } = useDropdowns();

  const cartStore = useCartStore();
  const cartWrapper = ref(null);

  let totalCartPrice = computed(() => {
    if (!cartStore.getTotalCartPrice) {
      return null;
    } else {
        let totalWithShipping = cartStore.getTotalCartPrice;
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(totalWithShipping);
    }
  });

  onBeforeMount(() => {
    cartStore.fetchCart(true);
  });

  const cartIsEmpty = computed(() => cartStore.cartItems.length === 0);
</script>

<template>
  <div class="cart-element__wrapper">
    <button v-if="isCartWideScreen" class="nav__elements--cart" :class="{'nav__elements--cart-open': isCartOpen}" @keydown="handleKeydown"
      ref="cartWrapper">
      <span class="amount" v-if="!cartIsEmpty">
        {{ cartStore.totalQuantity > 99 ? '99+' : cartStore.totalQuantity }}
      </span>
      <i class="fa-light fa-cart-shopping"></i>
    </button>
    <a v-else class="nav__elements--cart" :class="{'nav__elements--cart-open': isCartOpen}" :href="appendLanguagePrefixURL('/winkelwagen')">
      <span class="amount" v-if="!cartIsEmpty">
        {{ cartStore.totalQuantity > 99 ? '99+' : cartStore.totalQuantity }}
      </span>
      <i class="fa-light fa-cart-shopping"></i>
    </a>
    <div class="cart-wrapper cart-wrapper--popup" :class="{'cart-wrapper--popup-open': isCartOpen}" @click.self="handleKeydown">
      <div class="cart-wrapper__inner">
        <span class="cart-wrapper__title">
          <!-- TODO: Textual element -->
          Winkelwagen
        </span>
        <div class="cart__items" :class="{'cart__items--empty' : cartIsEmpty}">
          <div class="cart__item" v-for="item in cartStore.cartItems" v-if="!cartIsEmpty">
            <div class="cart__item-inner">
              <RBMImage :image="item.product.main_image" class="cart__item-image" />
              <div class="cart__item-title notranslate">
                <span v-if="item.amount > 1" class="item-quantity">{{ item.amount }}x</span>
                <a :href="appendLanguagePrefixURL(item.product.slug.startsWith('/') ? item.product.slug : '/' + item.product.slug)">{{ item.product.title }}</a>
              </div>
              <div class="cart__item-price" v-if="item">
                € {{ item.price.toFixed(2).replace('.', ',') }}
              </div>
            </div>
          </div>
          <div class="cart__empty" v-else-if="cartIsEmpty">
            Je winkelwagentje is leeg
          </div>
        </div>
        <div class="cart__total" v-if="!cartIsEmpty">
          <span class="cart__total-label">
            <!-- TODO: Tekstlabel -->
            Totaalbedrag
          </span>
          <div class="cart__total-price">
            <!-- TODO: Calculate the full price -->
            {{ totalCartPrice }}
            <div class="cart__total-price-label">
              <!-- TODO: HARDCODED-->
              (Exclusief btw)
            </div>
          </div>
        </div>
        <div class="cart-wrapper__actions" v-if="!cartIsEmpty">
          <!-- TODO: Textual elements -->
          <a :href="appendLanguagePrefixURL('/winkelwagen')" class="btn-text--primary-600">Bekijk winkelwagen</a>
          <a :href="appendLanguagePrefixURL('/afrekenen')" class="btn-solid--primary-600">Bestellen</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.amount {
  @apply content-[""] absolute text-white rounded-full text-[10px] h-[20px] w-[20px] bg-primary-600 flex items-center justify-center right-[-10px] top-5;
  @apply lg:top-3 lg:h-[16px] lg:w-[16px] lg:text-[9px] lg:right-[3px];
}

.cart-wrapper {
  @apply relative right-0 opacity-0 pointer-events-none transition-all duration-300 z-10;
  @apply sm:hidden;
}

.cart-wrapper--popup-open {
  @apply opacity-100 pointer-events-auto;
}

.cart-element__wrapper {
  @apply flex items-center h-full;
}

.cart-wrapper__inner {
  @apply absolute right-0 top-[36px] bg-white rounded shadow-card z-50 pt-[30px] pb-10 w-[500px] rounded-tr-none;
  @apply lg:top-[84px];
}

.cart-wrapper__title {
  @apply block font-bold font-fontText text-19_32_20_30 pl-9 mb-6;
}

.cart__items {
  @apply max-h-[405px] overflow-auto border-b border-solid border-[#E0E0E0];

  &.cart__items--empty {
    @apply border-b-0;
  }
}

.cart__item {
  @apply border-b border-solid border-[#E0E0E0] py-3;

  &:last-child {
    @apply border-b-0;
  }
}

.cart__item-image {
  @apply w-12 h-12 flex flex-[0_0_48px]
}

.cart__item-image--skeleton {
  @apply w-12 h-12 bg-lightGrey;
}

.cart__item-inner {
  @apply flex items-center px-9 gap-x-4;
}

.item-quantity {
  @apply font-bold mr-1;
}

.cart__item-title {
  @apply text-15_21_12_21;

  a {
    @apply hover:text-[#bf8a30];
  }
}

.cart-wrapper__actions {
  @apply items-center justify-end gap-x-12 flex px-9 mt-7;

  > * {
    @apply lg:flex-grow;
  }

  .btn-text--primary-600 {
    @apply font-normal text-15_20_14_20;
  }
}

.cart__total-price {
  @apply font-fontText font-bold text-15_20_14_20 text-right;
}

.cart__item-price {
  @apply ml-auto whitespace-nowrap;
  @extend .cart__total-price;
}

.cart__empty {
  @apply px-9 pb-9 text-center;
}

.cart__total {
  @apply flex px-9 justify-end mt-6 gap-x-[60px];
}

.cart__total-label {
  @apply font-bold;
}

.cart__total-price-label {
  @apply text-13_18_11_14 font-normal;
}

</style>
