<script setup>
    import { ref, computed } from "vue";
    import { useCartStore } from "../../stores/cart";

    import RBMImage from "./../../components/Utility/RBMImage.vue";
    import QuantitySelector from "./../../components/Utility/QuantitySelector.vue";
    import {appendLanguagePrefixURL} from "../../mixins/appendLanguageURL";

    const cartStore = useCartStore();

    const props = defineProps({
        item: Object,
        loading: Boolean
    });

    const QuantitySelectorValue = ref(null);

    const updateCartQuantity = (newQuantity) => {
      if (props.item.amount < newQuantity) {
        sendDataLayers('add to cart', {productId: props.item.product.id, amount: newQuantity});
      }
      if (props.item.amount > newQuantity) {
        sendDataLayers('remove from cart', {productId: props.item.product.id, amount: newQuantity});
      }
      QuantitySelectorValue.value = newQuantity;
      cartStore.editItemInCart(props.item, newQuantity);
    };

    const deleteCartItem = () => {
      sendDataLayers('remove from cart', {productId: props.item.product.id, amount: 0});
      cartStore.deleteItemInCart(props.item);
    };

    if (props.item?.amount) {
        QuantitySelectorValue.value = props.item.amount;
    }

    let calculatePrice = computed(() => {
        const price = props.item.price * QuantitySelectorValue.value;
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
    });

    // Winkelwagen items
    const cartItemStockText = ref(cartItemStockTextCode);
    const cartItemUspText = ref(cartItemUspTextCode);
    const cartItemDeleteText = ref(cartItemDeleteTextCode);
</script>

<template>
  <article class="cart__item">
    <div class="cart__item-content" v-if="!loading">
      <div class="cart__item-image" v-if="item.product.images[0]">
          <figure class="asp-base">
              <RBMImage :image="item.product.images[0]"/>
          </figure>
      </div>
      <div class="cart__item-main">
         <a :href="appendLanguagePrefixURL(item.product.slug.startsWith('/') ? item.product.slug : '/' + item.product.slug)" class="cart__item-title notranslate">{{ item.product.title  }}</a>

        <div class="cart__item-specifications">
            <span v-if="item.product.amount_in_stock >= 5" class="cart__item-stock">
              <i class="fa-solid fa-circle-check"></i>
              {{ Math.floor(item.product.amount_in_stock) }}
              {{ item.product.unit.toLowerCase() }}  {{ cartItemStockText.toLowerCase() }} <!-- VUE_CART_ITEM_STOCK -->
            </span>
            <span v-else-if="item.product.amount_in_stock < 5 && item.product.amount_in_stock > 0" class="cart__item-stock cart__item-stock--low">
              <i class="fa-solid fa-circle-xmark"></i>
              {{ Math.floor(item.product.amount_in_stock) }} {{ item.product.unit.toLowerCase() }}  {{ cartItemStockText.toLowerCase() }} <!-- VUE_CART_ITEM_STOCK -->
            </span>
            <!-- <span class="cart__item-stock">{{ item.product.amount_in_stock }} stuks op voorraad</span> -->
            <span class="cart__item-usp" v-if="item.product.amount_in_stock > 0">{{ item.product.delivery_time.in_stock }}</span> <!-- VUE_CART_ITEM_USP -->
            <span class="cart__item-usp" v-else>{{ item.product.delivery_time.out_of_stock }}</span> <!-- VUE_CART_ITEM_USP -->
        </div>

        <div class="cart__item-actions">
          <div class="cart__item-quantity">
              <QuantitySelector :amountOfItems="item.amount" :isCart="true" @updated-quantity="updateCartQuantity"/>
              <!-- TO DO HARDCODED TEXT -->
              <button class="cart__item-delete" @click="deleteCartItem">
                <span class="btn-text--small--primary-600">{{ cartItemDeleteText }}</span> <!-- VUE_CART_ITEM_DELETE -->
                <i class="fa-regular fa-xmark"></i>
              </button>
          </div>
          <div class="cart__item-pricing">
            <span class="cart__item-pricing-text">{{ calculatePrice }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cart__item-content" v-else>
      <div class="cart__item-image cart__item-image--skeleton">
          <!-- -->
      </div>
      <div class="cart__item-main">
        <span class="cart__item-title--skeleton"><!-- --></span>

        <div class="cart__item-specifications--skeleton">
            <!-- -->
        </div>

        <div class="cart__item-actions">
          <div class="cart__item-quantity">
            <span class="cart__item-quantity--skeleton">
                <!-- -->
            </span>
            <!-- TO DO HARDCODED TEXT -->
            <span class="cart__item-delete--skeleton">
              <!-- -->
            </span>
          </div>
          <div class="cart__item-pricing">
            <span class="cart__item-pricing-text--skeleton"><!-- --></span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import '../../../sass/elements/skeletons.scss';

.cart__item-content {
    @apply flex items-start gap-x-[30px] w-full relative pr-[25%] border-b border-solid border-border pt-6 pb-7;
    @apply sm:pr-0 sm:py-4 sm:gap-x-[18px];
}

.cart__item-main {
  @apply w-full;
}

.cart__item-image {
  @apply flex-[0_0_91px] w-[91px] h-[91px] relative;
  @apply mob:flex-[0_0_64px] mob:w-[64px] mob:h-[64px];
}

.cart__item-title {
    @apply block text-17_28_14_20 text-black mb-1;
    @apply hover:text-[#bf8a30];
}

.cart__item-specifications {
  @apply flex items-center flex-wrap gap-x-1.5 mb-2;
}

.cart__item-stock {
  @apply font-fontText font-bold text-14_16_12_14 text-green flex gap-x-1 items-center;

  &.cart__item-stock--low {
    @apply text-[#DE8C00];
  }

  &.cart__item-stock--out {
    @apply text-[#666666];
  }
}

.cart__item-usp {
  @apply text-13_18_11_14 text-text;
}

.cart__item-actions{
  @apply sm:flex sm:items-center sm:justify-between sm:flex-wrap sm:gap-x-4;
}

.cart__item-quantity {
  @apply flex items-center gap-x-4;
}

.cart__item-delete {
  @apply sm:h-10 sm:w-10 sm:flex sm:items-center;

  span {
    @apply sm:hidden;
  }

  i {
    @apply hidden text-[14px];
    @apply sm:block;
  }
}

.cart__item-pricing {
  @apply absolute inset-y-0 right-0 w-1/4 text-right flex items-center justify-end;
  @apply sm:relative sm:w-auto;
}

.cart__item-pricing-text {
    @apply whitespace-nowrap text-17_22_14_22 font-bold text-black;
}


.cart__item-image--skeleton {
  @extend .skeleton;
}

.cart__item-title--skeleton {
  @apply h-[20px] w-[483px] mb-2 inline-flex;
  @apply mob:w-full;
  @extend .skeleton;
}

.cart__item-specifications--skeleton {
  @apply h-[12px] w-[405px] mb-2 inline-flex;
  @apply mob:w-full mob:h-[24px];
  @extend .skeleton;
}

.cart__item-quantity--skeleton {
  @apply h-[40px] w-[140px];
  @extend .skeleton;
}

.cart__item-delete--skeleton {
  @apply h-[11px] w-[57px];
  @apply xs:hidden;
  @extend .skeleton;
}

.cart__item-pricing-text--skeleton {
  @apply h-[22px] w-[57px];
  @extend .skeleton;
}

</style>
