import { ref, shallowRef } from 'vue';

const visible = ref(false);
const tpl = shallowRef(null);
const tplData = ref(null);
const position = ref('top');

export const useDialog = () => {
    const toggle = () => {
        visible.value = !visible.value;

        // Reset the template and data
        if (visible.value === false) {
            tplData.value = null;
            tpl.value = null;
        }
    }

    const isVisible = () => {
        return visible.value;
    }

    const setView = (newTpl) => {
        tpl.value = newTpl;
    }

    const setData = (data) => {
        tplData.value = data;
    }

    const setPosition = (newPosition) => {
        position.value = newPosition;
    }

    const open = (tpl, data) => {
        setView(tpl);
        setData(data);
        toggle();
    }

    return {
        position,
        visible,
        tpl,
        tplData,
        isVisible,
        toggle,
        setView,
        setData,
        open,
        setPosition
    }
}
