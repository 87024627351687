<script setup>
  import { ref, computed, onMounted, onUnmounted } from 'vue';
  import OfficeInfoDialog from '../../components/Utility/Dialogs/OfficeInfo.vue';
  import { useDialog } from "../../composables/useDialog.js";

  const props = defineProps({
      shipping: Object,
      infobox: Object,
      office: Object,
  });

  const officeInfo = computed(() => {
    return {
      street: props.office.street || '',
      house_number: props.office.house_number || '',
      postal_code: props.office.postal_code || '',
      city: props.office.city || '',
      country: props.office.country || ''
    };
  });

  const openOfficeInfoDialog = async () => {
    const isMobile = ref(false);
    
    isMobile.value = window.innerWidth < 900;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    useDialog().open(OfficeInfoDialog, {'officeInfo': officeInfo, 'infoBox': props.infobox});
  };

</script>

<template>
  <div class="cart__radio">
    <input type="radio" :id="shipping.id" name="shipping_method" :value="shipping.id" :checked="shipping.active" @change="$emit('updateShippingMethod', shipping.id)" />
    <label class="radio__label" :for="shipping.id">
      {{ shipping.title }}
      <button type="button" @click="openOfficeInfoDialog()" v-if="shipping.is_pickup == 1"><i class="fa-solid fa-circle-info"></i></button>
    </label>
  </div>
</template>

<style lang="scss" scoped>
  .cart__radio {
    @apply relative;

    input[type="radio"] {
      @apply absolute inset-0 opacity-0;

      &:checked + .radio__label {
        &::before {
          @apply bg-[#FAF2E6] border-primary-500 content-["\f00c"];
        }
      }
    }

    &:has(~ .cart__radio) {
      @apply mb-2;
    }
  }

  .radio__label {
    @apply text-15_20_14_20 text-black flex items-center min-h-[28px] pl-10 relative cursor-pointer transition-colors duration-300;
    @apply md:pl-9;
    @apply hover:text-primary-600;

    i.fa-circle-info {
      @apply text-primary-600 text-18_20_14_20 ml-1;
    }

    &::before {
      @apply content-[""] absolute inset-y-0 left-0 my-auto border border-solid border-border h-[28px] w-[28px] rounded font-fontAwesome flex items-center justify-center text-center font-normal;
    }
  }
</style>
