import { defineStore } from 'pinia';

export const useShippingStore = defineStore('shipping', {
    state: () => {
        return {
            items: [],
            shippingIsLoading: false
        };
    },
    actions: {
        setShippingItems(items) {
            this.items = items;
        },
        async fillShippingStore() {
            this.shippingIsLoading = true;

            try {
                if (!this.items) {
                    const response = await fetch('/api/cart');

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const result = await response.json();

                    this.items = Object.values(result.available_shipping_methods);
                }
            } catch (error) {
                console.error('Failed to fetch cart data:', error);
            } finally {
                this.shippingIsLoading = false;
            }
        },
        async updateShippingMethod(shippingId = null) {
            let id = shippingId;
            if (id === null) {
                id = this.items[0].id;
            }

            try {
                const response = await fetch(`/api/cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"shipping_method":id})
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }

            this.items.forEach(item => {
                item.active = (item.id === id);
            });
        },
    },
    getters: {
        getSelectedShippingMethodPrice() {
            return this.items.find(item => item.active);
        },
    },
});


// Fill shipping store
//// Adds options for shipping

// Update shipping methods
//// Calls endpoint

// Get selected shipping method
//// Has price
