import { appendLanguagePrefixURL } from "./appendLanguageURL";

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {

	let pathname = args[0];
	try {
		const url = new URL(args[0]);
		if (url.origin.includes(document.baseURI)) {
			pathname = url.pathname;
			args[0] = appendLanguagePrefixURL(pathname);
		}
	} catch(error) {
		// meaning it's not a valid url (Url without host)
		args[0] = appendLanguagePrefixURL(pathname);
	}

	return await originalFetch(...args);
};
