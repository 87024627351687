export function useSetNewSwipers() {
  const setNewSwipers = () => {
    setTimeout(() => {
      const swiper = document.querySelectorAll('.swiper--vue');

      if (swiper.length > 0) {
        swiper.forEach(swiperElm => {
            if (swiperElm.dataset.sliderName) {
              swiperElm.removeAttribute('data-slider-name');
            }

            initSwiper(swiperElm);
        });
      }
    }, 250);
  };

  return setNewSwipers;
}
