<script setup>
  import { computed, ref } from 'vue';

  import { useCartStore } from "../../stores/cart";
  const cartStore = useCartStore();

  let deliveryPrice = computed(() => {
    if (cartStore.response?.data.shipping_costs || cartStore.response?.data.shipping_costs === 0) {
      return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(cartStore.response.data.shipping_costs);
    }
  });

  let totalCartPrice = computed(() => {
    if (cartStore.response?.data.total_price || cartStore.response?.data.total_price === 0) {
      return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(cartStore.response.data.total_price);
    }
  });

  const cartAsideDeliveryText = ref(cartAsideDeliveryTextCode);
  const cartAsideTotalText = ref(cartAsideTotalTextCode);
  const cartAsideRemarkText = ref(cartAsideRemarkTextCode);
</script>

<template>
  <div class="cart-aside__pricing"  v-if="deliveryPrice">
    <div class="cart-aside__delivery-price">
      <span class="cart-aside__label">{{ cartAsideDeliveryText }}</span> <!-- VUE_CART_ASIDE_DELIVERY -->
      <span class="cart-aside__price">{{ deliveryPrice }}</span>
    </div>
    <div class="cart-aside__total-price" v-if="totalCartPrice">
      <span class="cart-aside__label">{{ cartAsideTotalText }}</span> <!-- VUE_CART_ASIDE_TOTAL -->
      <span class="cart-aside__price">{{ totalCartPrice }}</span>
    </div>
    <div class="cart-aside__remark">
      <span class="cart-aside__remark-text">{{ cartAsideRemarkText }}</span>  <!-- VUE_CART_ASIDE_REMARK -->
    </div>
  </div>
  <div class="cart-aside__pricing" v-else>
    <span class="cart-aside__pricing--skeleton"><!-- --></span>
    <span class="cart-aside__pricing--skeleton"><!-- --></span>
    <span class="cart-aside__pricing--skeleton"><!-- --></span>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../sass/elements/skeletons.scss';

.cart-aside__pricing {
  @apply px-9 pt-6 pb-5 border-t border-solid border-border;
  @apply md:px-0 md:pt-4 md:pb-0;
}

.cart-aside__delivery-price,
.cart-aside__total-price {
  @apply flex items-center justify-between gap-x-4 flex-wrap;

  + * {
    @apply mt-2;

    &.cart-aside__remark {
      @apply md:mt-1;
    }
  }
}

.cart-aside__label,
.cart-aside__price {
  @apply font-fontText font-bold text-15_20_14_20 text-text;
}

.cart-aside__remark {
    @apply text-right block;
}

.cart-aside__remark-text {
  @apply text-15_20_14_20 text-[#4D4D4D];
}

.cart-aside__pricing--skeleton {
  @apply w-full h-[20px] flex;
  @extend .skeleton;

  + .cart-aside__pricing--skeleton {
    @apply mt-3;
  }
}
</style>
