<script setup>
  import { ref, watch } from 'vue';
  import { useProfileStore } from '../stores/mainStore';
  import DeviceRow from '../components/DeviceRow.vue';
  import DataView from "primevue/dataview";

  const profileStore = useProfileStore();

  const devices = ref(profileStore.devices);
  const activeButton = ref('all');
  const openDropdown = ref(false);

  watch(
    () => profileStore.devices,
    (newVal) => {
      if (newVal) {
        devices.value = newVal;
      }
    }
  );

  const devicesByType = (type) => {
    devices.value = profileStore.getDevicesByType(type);
    activeButton.value = type;
  }

  const showAllDevices = () => {
    devices.value = profileStore.devices;
    activeButton.value = 'all';
  }

  const paginationBehaviour = () => {
    const productsItems = document.getElementById('dashboard-devices__filter');

    if (productsItems) {
      const topPosition = productsItems.getBoundingClientRect().top + window.scrollY - 200;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  }

  const toggleOpenDropdown = () => {
    openDropdown.value = !openDropdown.value;
  }

</script>

<template>
  <section class="dashboard-header dashboard-header--devices" aria-labelledby="dashboard__title">
    <div class="dashboard-header__content">
      <!-- TO DO HARDCODED -->
      <h1 id="dashboard__title">Mijn apparaten</h1>
      <!-- <p>Lorem ipsum dolor sit amet consectetur adisciping elit maecanas viverra.</p> -->
    </div>
  </section>

  <section class="dashboard-devices-filter" aria-label="Apparaten filter" id="dashboard-devices__filter">
    <div class="dashboard-devices-filter__content--desktop">
      <!-- TO DO HARDCODED -->
      <div class="dashboard-devices-filter__item">
        <button :class="{ active: activeButton === 'all' }" @click="showAllDevices">Alle apparaten</button>
      </div>
      <div class="dashboard-devices-filter__item">
        <button :class="{ active: activeButton === 'Lassen' }" @click="devicesByType('Lassen')">Lasapparaten</button>
      </div>
      <div class="dashboard-devices-filter__item">
        <button :class="{ active: activeButton === 'Meten' }" @click="devicesByType('Meten')">Meetapparaten</button>
      </div>
      <div class="dashboard-devices-filter__item">
        <button :class="{ active: activeButton === 'Blazen' }" @click="devicesByType('Blazen')">Blaasapparaten</button>
      </div>

    </div>
    <div class="dashboard-devices-filter__content--mobile">
      <div class="dashboard-devices-filter__wrapper">
        <span class="dashboard-devices-filter__label">Toon:</span>

        <div class="dashboard-devices-filter__items" :class="{ 'dashboard-devices-filter__items--active': openDropdown }">
          <div class="dashboard-devices-filter__items-content">
            <div class="dashboard-devices-filter__trigger" @click="toggleOpenDropdown">
              <span v-if="activeButton === 'all'">Alle apparaten</span>
              <span v-else-if="activeButton === 'Lassen'">Lasapparaten</span>
              <span v-else-if="activeButton === 'Meten'">Meetapparaten</span>
              <span v-else-if="activeButton === 'Blazen'">Blaasapparaten</span>
            </div>
            <div class="dashboard-devices-filter__dropdown">
              <button v-if="activeButton != 'all'" :class="{ active: activeButton === 'all' }" @click="showAllDevices">Alle apparaten</button>
              <button v-if="activeButton != 'Lassen'" :class="{ active: activeButton === 'Lassen' }" @click="devicesByType('Lassen')">Lasapparaten</button>
              <button v-if="activeButton != 'Meten'" :class="{ active: activeButton === 'Meten' }" @click="devicesByType('Meten')">Meetapparaten</button>
              <button v-if="activeButton != 'Blazen'" :class="{ active: activeButton === 'Blazen' }" @click="devicesByType('Blazen')">Blaasapparaten</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-if="!profileStore.isPrimaryStoreFilled" class="dashboard-addresses">
    <div class="dashboard-skeleton--block"><!-- --></div>
  </div>       

  <div class="dashboard-devices-items" v-if="devices.length > 0">
    <DataView
      :value="devices"
      paginator
      :paginatorTemplate="'PrevPageLink PageLinks NextPageLink'"
      :rows="20"
      :alwaysShowPaginator="false"
      unstyled
      @page="paginationBehaviour"
    >
      <template #list="slotProps">
        <div class="dashboard-devices-table">
          <div class="dashboard-devices-table__header">
            <div class="dashboard-devices-table__header-item">Apparaat</div>
            <div class="dashboard-devices-table__header-item">Aankoopdatum</div>
            <div class="dashboard-devices-table__header-item">Laatste onderhoud</div>
            <div class="dashboard-devices-table__header-item">Onderhoud nodig</div>
          </div>

          <DeviceRow
            v-for="device in slotProps.items"
            :device="device"
          />
        </div>
      </template>
    </DataView>
  </div>
  <div v-else-if="profileStore.isPrimaryStoreFilled">
    <span class="">Geen apparaten gevonden</span>
  </div>  
</template>

<style lang="scss" scoped>
  @import '../../../sass/elements/skeletons.scss';

  // Adding specific styling here isntead of in scss like other dashboard elements, this view is Maunt specific
  .dashboard-devices-filter__content--desktop {
    @apply lg:hidden;
  }

  .dashboard-devices-filter__content--mobile {
    @apply hidden;
    @apply lg:block;
  }

  .dashboard-devices-table {
    @apply table w-full border-collapse;
    @apply md:block;
  }

  .dashboard-devices-table__header {
    @apply table-row;
    @apply md:hidden;

    > div {
      @apply table-cell;
    }
  }

    .dashboard-skeleton--filters {
    @extend .skeleton;
    @apply h-[48px] w-full mt-3;
  }

  .dashboard-skeleton--block {
    @extend .skeleton;
    @apply h-[239px] w-full mt-3;
  }
</style>
